/* tslint:disable */
/* eslint-disable */
/**
 * Saikan-Shoin API
 * API for Saikan-Shoin.
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface Assignment
 */
export interface Assignment {
  /**
   *
   * @type {number}
   * @memberof Assignment
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof Assignment
   */
  font_id?: number;
  /**
   *
   * @type {string}
   * @memberof Assignment
   */
  image_url?: string;
}
/**
 *
 * @export
 * @interface AssignmentAllOf
 */
export interface AssignmentAllOf {
  /**
   *
   * @type {string}
   * @memberof AssignmentAllOf
   */
  image_url?: string;
}
/**
 *
 * @export
 * @interface AssignmentCount
 */
export interface AssignmentCount {
  /**
   * 課題提出者数（※提出課題数の合計ではない）
   * @type {number}
   * @memberof AssignmentCount
   */
  participant_count?: number;
  /**
   *
   * @type {Array<AssignmentCountFontsInner>}
   * @memberof AssignmentCount
   */
  fonts?: Array<AssignmentCountFontsInner>;
}
/**
 *
 * @export
 * @interface AssignmentCountFontsInner
 */
export interface AssignmentCountFontsInner {
  /**
   *
   * @type {number}
   * @memberof AssignmentCountFontsInner
   */
  font_id?: number;
  /**
   *
   * @type {number}
   * @memberof AssignmentCountFontsInner
   */
  grade_id?: number;
  /**
   * 書体ごとの提出課題数
   * @type {number}
   * @memberof AssignmentCountFontsInner
   */
  count?: number;
}
/**
 * 課題ID
 * @export
 * @interface AssignmentId
 */
export interface AssignmentId {
  /**
   *
   * @type {number}
   * @memberof AssignmentId
   */
  id?: number;
}
/**
 *
 * @export
 * @interface AssignmentUser
 */
export interface AssignmentUser {
  /**
   * 課題ID
   * @type {number}
   * @memberof AssignmentUser
   */
  assignment_id?: number;
  /**
   * ユーザー名
   * @type {string}
   * @memberof AssignmentUser
   */
  nickname?: string;
  /**
   * 課題が採点されたらtrue
   * @type {boolean}
   * @memberof AssignmentUser
   */
  is_reviewed?: boolean;
}
/**
 *
 * @export
 * @interface Classroom
 */
export interface Classroom {
  /**
   * 教室名
   * @type {string}
   * @memberof Classroom
   */
  name: string;
  /**
   * 教室の代表先生の名前
   * @type {string}
   * @memberof Classroom
   */
  teacher_name: string;
  /**
   * 教室の代表先生のメールアドレス
   * @type {string}
   * @memberof Classroom
   */
  teacher_email: string;
  /**
   * 生徒の属性(学生, 一般, 混合, その他)
   * @type {string}
   * @memberof Classroom
   */
  student_type: string;
}
/**
 * Stripeのクライアントシークレット
 * @export
 * @interface ClientSecret
 */
export interface ClientSecret {
  /**
   *
   * @type {string}
   * @memberof ClientSecret
   */
  client_secret?: string;
}
/**
 *
 * @export
 * @interface CommentTemplate
 */
export interface CommentTemplate {
  /**
   * コメントテンプレートID
   * @type {number}
   * @memberof CommentTemplate
   */
  id?: number;
  /**
   *
   * @type {CommentTemplateCategory}
   * @memberof CommentTemplate
   */
  category?: CommentTemplateCategory;
  /**
   *
   * @type {string}
   * @memberof CommentTemplate
   */
  comment?: string;
}
/**
 *
 * @export
 * @interface CommentTemplateCategory
 */
export interface CommentTemplateCategory {
  /**
   *
   * @type {number}
   * @memberof CommentTemplateCategory
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof CommentTemplateCategory
   */
  name?: string;
}
/**
 * ユーザーが本登録済みかどうか
 * @export
 * @interface CompletedSignUp
 */
export interface CompletedSignUp {
  /**
   *
   * @type {boolean}
   * @memberof CompletedSignUp
   */
  completed_sign_up?: boolean;
}
/**
 *
 * @export
 * @interface CreateHandbook
 */
export interface CreateHandbook {
  /**
   *
   * @type {string}
   * @memberof CreateHandbook
   */
  theme?: string | null;
  /**
   *
   * @type {UpdateHandbookDemonstration}
   * @memberof CreateHandbook
   */
  demonstration: UpdateHandbookDemonstration;
  /**
   *
   * @type {UpdateHandbookExplanation}
   * @memberof CreateHandbook
   */
  explanation: UpdateHandbookExplanation;
  /**
   *
   * @type {number}
   * @memberof CreateHandbook
   */
  year: number;
  /**
   *
   * @type {number}
   * @memberof CreateHandbook
   */
  month: number;
  /**
   *
   * @type {number}
   * @memberof CreateHandbook
   */
  font_id: number;
  /**
   *
   * @type {number}
   * @memberof CreateHandbook
   */
  grade_id: number | null;
}
/**
 *
 * @export
 * @interface CreateHandbookAllOf
 */
export interface CreateHandbookAllOf {
  /**
   *
   * @type {number}
   * @memberof CreateHandbookAllOf
   */
  year?: number;
  /**
   *
   * @type {number}
   * @memberof CreateHandbookAllOf
   */
  month?: number;
  /**
   *
   * @type {number}
   * @memberof CreateHandbookAllOf
   */
  font_id?: number;
  /**
   *
   * @type {number}
   * @memberof CreateHandbookAllOf
   */
  grade_id?: number | null;
}
/**
 *
 * @export
 * @interface CreateReview
 */
export interface CreateReview {
  /**
   * 採点項目の点数の配列
   * @type {Array<CreateReviewScoresInner>}
   * @memberof CreateReview
   */
  scores?: Array<CreateReviewScoresInner>;
  /**
   * 審査所感テンプレートIDの配列
   * @type {Array<number>}
   * @memberof CreateReview
   */
  template_comment_ids?: Array<number>;
  /**
   * 自由入力の審査所感
   * @type {string}
   * @memberof CreateReview
   */
  free_comment?: string;
}
/**
 *
 * @export
 * @interface CreateReviewScoresInner
 */
export interface CreateReviewScoresInner {
  /**
   * 採点項目ID
   * @type {number}
   * @memberof CreateReviewScoresInner
   */
  viewpoint_id?: number;
  /**
   * 点数
   * @type {number}
   * @memberof CreateReviewScoresInner
   */
  point?: number;
}
/**
 * 登録する際にリクエストに含めるユーザー情報
 * @export
 * @interface CreateUser
 */
export interface CreateUser {
  /**
   *
   * @type {Array<UserBaseRolesInner>}
   * @memberof CreateUser
   */
  roles?: Array<UserBaseRolesInner>;
  /**
   *
   * @type {string}
   * @memberof CreateUser
   */
  last_name: string;
  /**
   *
   * @type {string}
   * @memberof CreateUser
   */
  first_name: string;
  /**
   *
   * @type {string}
   * @memberof CreateUser
   */
  nickname: string;
  /**
   *
   * @type {number}
   * @memberof CreateUser
   */
  course_type: number;
  /**
   *
   * @type {string}
   * @memberof CreateUser
   */
  email: string;
  /**
   *
   * @type {number}
   * @memberof CreateUser
   */
  gender_id: number;
  /**
   *
   * @type {string}
   * @memberof CreateUser
   */
  birthday: string;
  /**
   *
   * @type {string}
   * @memberof CreateUser
   */
  prefecture: string;
  /**
   *
   * @type {boolean}
   * @memberof CreateUser
   */
  is_experienced: boolean;
  /**
   *
   * @type {string}
   * @memberof CreateUser
   */
  parent_last_name?: string;
  /**
   *
   * @type {string}
   * @memberof CreateUser
   */
  parent_first_name?: string;
  /**
   *
   * @type {UserBaseCreditCard}
   * @memberof CreateUser
   */
  credit_card?: UserBaseCreditCard;
  /**
   *
   * @type {boolean}
   * @memberof CreateUser
   */
  is_subscribed?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CreateUser
   */
  completed_sign_up?: boolean;
}
/**
 *
 * @export
 * @interface Font
 */
export interface Font {
  /**
   *
   * @type {FontFont}
   * @memberof Font
   */
  font: FontFont;
}
/**
 *
 * @export
 * @interface FontFont
 */
export interface FontFont {
  /**
   *
   * @type {number}
   * @memberof FontFont
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof FontFont
   */
  name: string;
}
/**
 *
 * @export
 * @interface FontId
 */
export interface FontId {
  /**
   *
   * @type {number}
   * @memberof FontId
   */
  font_id?: number;
}
/**
 *
 * @export
 * @interface FontsInner
 */
export interface FontsInner {
  /**
   *
   * @type {number}
   * @memberof FontsInner
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof FontsInner
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof FontsInner
   */
  course_type?: number;
}
/**
 *
 * @export
 * @interface GetAssignments200ResponseInner
 */
export interface GetAssignments200ResponseInner {
  /**
   *
   * @type {number}
   * @memberof GetAssignments200ResponseInner
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof GetAssignments200ResponseInner
   */
  font_id?: number;
  /**
   *
   * @type {string}
   * @memberof GetAssignments200ResponseInner
   */
  image_url?: string;
}
/**
 *
 * @export
 * @interface GetAssignmentsUsername200ResponseInner
 */
export interface GetAssignmentsUsername200ResponseInner {
  /**
   * 課題ID
   * @type {number}
   * @memberof GetAssignmentsUsername200ResponseInner
   */
  assignment_id?: number;
  /**
   * ユーザー名
   * @type {string}
   * @memberof GetAssignmentsUsername200ResponseInner
   */
  nickname?: string;
  /**
   * 課題が採点されたらtrue
   * @type {boolean}
   * @memberof GetAssignmentsUsername200ResponseInner
   */
  is_reviewed?: boolean;
}
/**
 * @type GetResultsRankIdParameter
 * @export
 */
export type GetResultsRankIdParameter = number | string;

/**
 *
 * @export
 * @interface Handbook
 */
export interface Handbook {
  /**
   *
   * @type {FontFont}
   * @memberof Handbook
   */
  font: FontFont;
  /**
   *
   * @type {number}
   * @memberof Handbook
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof Handbook
   */
  year: number;
  /**
   *
   * @type {number}
   * @memberof Handbook
   */
  month: number;
  /**
   *
   * @type {number}
   * @memberof Handbook
   */
  grade_id: number | null;
  /**
   *
   * @type {string}
   * @memberof Handbook
   */
  theme: string | null;
  /**
   *
   * @type {HandbookAllOfDemonstration}
   * @memberof Handbook
   */
  demonstration: HandbookAllOfDemonstration;
  /**
   *
   * @type {HandbookAllOfExplanation}
   * @memberof Handbook
   */
  explanation: HandbookAllOfExplanation;
}
/**
 *
 * @export
 * @interface HandbookAllOf
 */
export interface HandbookAllOf {
  /**
   *
   * @type {number}
   * @memberof HandbookAllOf
   */
  id?: number;
  /**
   *
   * @type {number}
   * @memberof HandbookAllOf
   */
  year?: number;
  /**
   *
   * @type {number}
   * @memberof HandbookAllOf
   */
  month?: number;
  /**
   *
   * @type {number}
   * @memberof HandbookAllOf
   */
  grade_id?: number | null;
  /**
   *
   * @type {string}
   * @memberof HandbookAllOf
   */
  theme?: string | null;
  /**
   *
   * @type {HandbookAllOfDemonstration}
   * @memberof HandbookAllOf
   */
  demonstration?: HandbookAllOfDemonstration;
  /**
   *
   * @type {HandbookAllOfExplanation}
   * @memberof HandbookAllOf
   */
  explanation?: HandbookAllOfExplanation;
}
/**
 *
 * @export
 * @interface HandbookAllOfDemonstration
 */
export interface HandbookAllOfDemonstration {
  /**
   *
   * @type {Array<string>}
   * @memberof HandbookAllOfDemonstration
   */
  video_urls: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof HandbookAllOfDemonstration
   */
  image_urls: Array<string>;
  /**
   *
   * @type {string}
   * @memberof HandbookAllOfDemonstration
   */
  comment: string | null;
}
/**
 *
 * @export
 * @interface HandbookAllOfExplanation
 */
export interface HandbookAllOfExplanation {
  /**
   *
   * @type {Array<string>}
   * @memberof HandbookAllOfExplanation
   */
  video_urls: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof HandbookAllOfExplanation
   */
  image_urls: Array<string>;
  /**
   *
   * @type {string}
   * @memberof HandbookAllOfExplanation
   */
  comment: string | null;
}
/**
 *
 * @export
 * @interface HandbooksAvailTermsInner
 */
export interface HandbooksAvailTermsInner {
  /**
   *
   * @type {number}
   * @memberof HandbooksAvailTermsInner
   */
  year?: number;
  /**
   *
   * @type {number}
   * @memberof HandbooksAvailTermsInner
   */
  month?: number;
  /**
   *
   * @type {number}
   * @memberof HandbooksAvailTermsInner
   */
  term_status?: number;
}
/**
 *
 * @export
 * @interface HighResultsInner
 */
export interface HighResultsInner {
  /**
   *
   * @type {ResultsItemsInnerUser}
   * @memberof HighResultsInner
   */
  user?: ResultsItemsInnerUser;
  /**
   *
   * @type {ResultsItemsInnerFont}
   * @memberof HighResultsInner
   */
  font?: ResultsItemsInnerFont;
  /**
   *
   * @type {HighResultsInnerRankOrders}
   * @memberof HighResultsInner
   */
  rank_orders?: HighResultsInnerRankOrders;
  /**
   * scoresの合計point
   * @type {number}
   * @memberof HighResultsInner
   */
  score_amount?: number;
  /**
   * 課題の画像URL
   * @type {string}
   * @memberof HighResultsInner
   */
  image_url?: string;
  /**
   *
   * @type {ResultsItemsInnerRank}
   * @memberof HighResultsInner
   */
  rank?: ResultsItemsInnerRank;
  /**
   *
   * @type {Array<ResultsItemsInnerScoresInner>}
   * @memberof HighResultsInner
   */
  scores?: Array<ResultsItemsInnerScoresInner>;
}
/**
 * 順位の配列
 * @export
 * @interface HighResultsInnerRankOrders
 */
export interface HighResultsInnerRankOrders {
  /**
   *
   * @type {HighResultsInnerRankOrdersSameRankOrder}
   * @memberof HighResultsInnerRankOrders
   */
  same_rank_order?: HighResultsInnerRankOrdersSameRankOrder;
  /**
   *
   * @type {HighResultsInnerRankOrdersWholeOrder}
   * @memberof HighResultsInnerRankOrders
   */
  whole_order?: HighResultsInnerRankOrdersWholeOrder;
}
/**
 * 同位内での課題の順位
 * @export
 * @interface HighResultsInnerRankOrdersSameRankOrder
 */
export interface HighResultsInnerRankOrdersSameRankOrder {
  /**
   * 同位順位
   * @type {number}
   * @memberof HighResultsInnerRankOrdersSameRankOrder
   */
  order?: number;
}
/**
 * 全体内での課題の順位
 * @export
 * @interface HighResultsInnerRankOrdersWholeOrder
 */
export interface HighResultsInnerRankOrdersWholeOrder {
  /**
   * 同位順位
   * @type {number}
   * @memberof HighResultsInnerRankOrdersWholeOrder
   */
  order?: number;
}
/**
 *
 * @export
 * @interface InputClassroom
 */
export interface InputClassroom {
  /**
   * 教室名
   * @type {string}
   * @memberof InputClassroom
   */
  name: string;
  /**
   * 生徒の属性(学生, 一般, 混合, その他)
   * @type {number}
   * @memberof InputClassroom
   */
  student_type: number;
  /**
   * 教室の代表先生のメールアドレス
   * @type {string}
   * @memberof InputClassroom
   */
  teacher_email: string;
}
/**
 * 成績非公開設定
 * @export
 * @interface IsPrivate
 */
export interface IsPrivate {
  /**
   *
   * @type {boolean}
   * @memberof IsPrivate
   */
  is_private?: boolean;
}
/**
 *
 * @export
 * @interface LatestResults
 */
export interface LatestResults {
  /**
   * 年
   * @type {number}
   * @memberof LatestResults
   */
  year?: number;
  /**
   * 月
   * @type {number}
   * @memberof LatestResults
   */
  month?: number;
  /**
   *
   * @type {Array<LatestResultsAssignmentsInner>}
   * @memberof LatestResults
   */
  assignments?: Array<LatestResultsAssignmentsInner>;
}
/**
 *
 * @export
 * @interface LatestResultsAssignmentsInner
 */
export interface LatestResultsAssignmentsInner {
  /**
   * 書体ID
   * @type {number}
   * @memberof LatestResultsAssignmentsInner
   */
  font_id?: number | null;
  /**
   * 最新で採点された課題の画像URL
   * @type {string}
   * @memberof LatestResultsAssignmentsInner
   */
  image_url?: string;
}
/**
 *
 * @export
 * @interface ModelError
 */
export interface ModelError {
  /**
   *
   * @type {number}
   * @memberof ModelError
   */
  code: number;
  /**
   *
   * @type {string}
   * @memberof ModelError
   */
  message: string;
}
/**
 *
 * @export
 * @interface OverallResult
 */
export interface OverallResult {
  /**
   *
   * @type {ResultsItemsInnerUser}
   * @memberof OverallResult
   */
  user?: ResultsItemsInnerUser;
  /**
   *
   * @type {Array<OverallResultResultsInner>}
   * @memberof OverallResult
   */
  results?: Array<OverallResultResultsInner>;
}
/**
 *
 * @export
 * @interface OverallResultResultsInner
 */
export interface OverallResultResultsInner {
  /**
   *
   * @type {OverallResultResultsInnerFont}
   * @memberof OverallResultResultsInner
   */
  font?: OverallResultResultsInnerFont;
  /**
   *
   * @type {ResultsItemsInnerRankCurrent}
   * @memberof OverallResultResultsInner
   */
  rank?: ResultsItemsInnerRankCurrent;
  /**
   *
   * @type {Array<ResultsItemsInnerScoresInner>}
   * @memberof OverallResultResultsInner
   */
  scores?: Array<ResultsItemsInnerScoresInner>;
}
/**
 *
 * @export
 * @interface OverallResultResultsInnerFont
 */
export interface OverallResultResultsInnerFont {
  /**
   * 書体ID
   * @type {number}
   * @memberof OverallResultResultsInnerFont
   */
  id: number | null;
  /**
   * 書体名
   * @type {string}
   * @memberof OverallResultResultsInnerFont
   */
  name: string;
}
/**
 *
 * @export
 * @interface OverallResults
 */
export interface OverallResults {
  /**
   * 総合段級位の総数
   * @type {number}
   * @memberof OverallResults
   */
  total?: number;
  /**
   *
   * @type {Array<OverallResult>}
   * @memberof OverallResults
   */
  items?: Array<OverallResult>;
}
/**
 * Stripeの支払い方法
 * @export
 * @interface PaymentMethod
 */
export interface PaymentMethod {
  /**
   *
   * @type {string}
   * @memberof PaymentMethod
   */
  payment_method_id?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentMethod
   */
  brand?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentMethod
   */
  last4?: string;
  /**
   *
   * @type {number}
   * @memberof PaymentMethod
   */
  exp_month?: number;
  /**
   *
   * @type {number}
   * @memberof PaymentMethod
   */
  exp_year?: number;
}
/**
 *
 * @export
 * @interface PostAssignmentRequest
 */
export interface PostAssignmentRequest {
  /**
   *
   * @type {string}
   * @memberof PostAssignmentRequest
   */
  user_id: string;
  /**
   *
   * @type {number}
   * @memberof PostAssignmentRequest
   */
  handbook_id: number;
  /**
   *
   * @type {number}
   * @memberof PostAssignmentRequest
   */
  year?: number;
  /**
   *
   * @type {number}
   * @memberof PostAssignmentRequest
   */
  month?: number;
  /**
   * 画像ファイル
   * @type {File}
   * @memberof PostAssignmentRequest
   */
  file: File;
}
/**
 *
 * @export
 * @interface RankArchivesInner
 */
export interface RankArchivesInner {
  /**
   *
   * @type {RankArchivesInnerFont}
   * @memberof RankArchivesInner
   */
  font?: RankArchivesInnerFont;
  /**
   *
   * @type {Array<RankArchivesInnerResultsInner>}
   * @memberof RankArchivesInner
   */
  results?: Array<RankArchivesInnerResultsInner>;
}
/**
 *
 * @export
 * @interface RankArchivesInnerFont
 */
export interface RankArchivesInnerFont {
  /**
   * 書体ID
   * @type {number}
   * @memberof RankArchivesInnerFont
   */
  id?: number | null;
  /**
   * 書体名
   * @type {string}
   * @memberof RankArchivesInnerFont
   */
  name?: string;
}
/**
 *
 * @export
 * @interface RankArchivesInnerResultsInner
 */
export interface RankArchivesInnerResultsInner {
  /**
   * 月
   * @type {number}
   * @memberof RankArchivesInnerResultsInner
   */
  month?: number;
  /**
   *
   * @type {RankArchivesInnerResultsInnerRank}
   * @memberof RankArchivesInnerResultsInner
   */
  rank?: RankArchivesInnerResultsInnerRank;
}
/**
 *
 * @export
 * @interface RankArchivesInnerResultsInnerRank
 */
export interface RankArchivesInnerResultsInnerRank {
  /**
   * 該当年月の段級位を表現する数字 (10級 → 1, ~, 師範 → 21)
   * @type {number}
   * @memberof RankArchivesInnerResultsInnerRank
   */
  order?: number;
  /**
   * 課題提出時の段級位を表現する数字 (10級 → 1, ~, 師範 → 21)
   * @type {number}
   * @memberof RankArchivesInnerResultsInnerRank
   */
  pre_rank_order?: number;
}
/**
 *
 * @export
 * @interface RankWithFont
 */
export interface RankWithFont {
  /**
   *
   * @type {number}
   * @memberof RankWithFont
   */
  font_id?: number | null;
  /**
   *
   * @type {string}
   * @memberof RankWithFont
   */
  rank_name?: string;
}
/**
 *
 * @export
 * @interface Result
 */
export interface Result {
  /**
   *
   * @type {ResultUser}
   * @memberof Result
   */
  user?: ResultUser;
  /**
   *
   * @type {ResultOverallRank}
   * @memberof Result
   */
  overall_rank?: ResultOverallRank;
  /**
   *
   * @type {Array<ResultAssignmentsInner>}
   * @memberof Result
   */
  assignments?: Array<ResultAssignmentsInner>;
}
/**
 *
 * @export
 * @interface ResultAssignmentsInner
 */
export interface ResultAssignmentsInner {
  /**
   *
   * @type {ResultAssignmentsInnerFont}
   * @memberof ResultAssignmentsInner
   */
  font?: ResultAssignmentsInnerFont;
  /**
   * 50点満点の点数
   * @type {number}
   * @memberof ResultAssignmentsInner
   */
  amount?: number;
  /**
   * 提出した課題画像
   * @type {string}
   * @memberof ResultAssignmentsInner
   */
  image_url?: string;
  /**
   * 審査所感
   * @type {Array<string>}
   * @memberof ResultAssignmentsInner
   */
  comments?: Array<string>;
  /**
   *
   * @type {ResultAssignmentsInnerRankOrder}
   * @memberof ResultAssignmentsInner
   */
  rank_order?: ResultAssignmentsInnerRankOrder;
  /**
   *
   * @type {ResultAssignmentsInnerRank}
   * @memberof ResultAssignmentsInner
   */
  rank?: ResultAssignmentsInnerRank;
  /**
   * 採点項目の点数の配列
   * @type {Array<ResultAssignmentsInnerScoresInner>}
   * @memberof ResultAssignmentsInner
   */
  scores?: Array<ResultAssignmentsInnerScoresInner>;
}
/**
 *
 * @export
 * @interface ResultAssignmentsInnerFont
 */
export interface ResultAssignmentsInnerFont {
  /**
   * 書体ID
   * @type {number}
   * @memberof ResultAssignmentsInnerFont
   */
  id?: number;
  /**
   * 書体名
   * @type {string}
   * @memberof ResultAssignmentsInnerFont
   */
  name?: string;
}
/**
 *
 * @export
 * @interface ResultAssignmentsInnerRank
 */
export interface ResultAssignmentsInnerRank {
  /**
   *
   * @type {ResultAssignmentsInnerRankCurrent}
   * @memberof ResultAssignmentsInnerRank
   */
  current?: ResultAssignmentsInnerRankCurrent;
  /**
   *
   * @type {ResultAssignmentsInnerRankPrevious}
   * @memberof ResultAssignmentsInnerRank
   */
  previous?: ResultAssignmentsInnerRankPrevious;
  /**
   * 昇段フラグ
   * @type {boolean}
   * @memberof ResultAssignmentsInnerRank
   */
  is_rank_up?: boolean;
}
/**
 * 採点後の書体段級位
 * @export
 * @interface ResultAssignmentsInnerRankCurrent
 */
export interface ResultAssignmentsInnerRankCurrent {
  /**
   * 採点後の書体段級位ID
   * @type {number}
   * @memberof ResultAssignmentsInnerRankCurrent
   */
  id?: number;
  /**
   * 段級位名
   * @type {string}
   * @memberof ResultAssignmentsInnerRankCurrent
   */
  name?: string;
}
/**
 *
 * @export
 * @interface ResultAssignmentsInnerRankOrder
 */
export interface ResultAssignmentsInnerRankOrder {
  /**
   *
   * @type {ResultAssignmentsInnerRankOrderSameRankOrder}
   * @memberof ResultAssignmentsInnerRankOrder
   */
  same_rank_order?: ResultAssignmentsInnerRankOrderSameRankOrder;
  /**
   *
   * @type {ResultAssignmentsInnerRankOrderWholeOrder}
   * @memberof ResultAssignmentsInnerRankOrder
   */
  whole_order?: ResultAssignmentsInnerRankOrderWholeOrder;
}
/**
 * 同位順位
 * @export
 * @interface ResultAssignmentsInnerRankOrderSameRankOrder
 */
export interface ResultAssignmentsInnerRankOrderSameRankOrder {
  /**
   * 同位順位
   * @type {number}
   * @memberof ResultAssignmentsInnerRankOrderSameRankOrder
   */
  order?: number;
  /**
   * 同位順位の母数(提出時同じ段級位のユーザーかつ同じ手本に対する提出数)
   * @type {number}
   * @memberof ResultAssignmentsInnerRankOrderSameRankOrder
   */
  total?: number;
}
/**
 * 全体順位
 * @export
 * @interface ResultAssignmentsInnerRankOrderWholeOrder
 */
export interface ResultAssignmentsInnerRankOrderWholeOrder {
  /**
   * 全体順位
   * @type {number}
   * @memberof ResultAssignmentsInnerRankOrderWholeOrder
   */
  order?: number;
  /**
   * 全体順位の母数(同じ手本に対する提出数)
   * @type {number}
   * @memberof ResultAssignmentsInnerRankOrderWholeOrder
   */
  total?: number;
}
/**
 * 採点前の段級位
 * @export
 * @interface ResultAssignmentsInnerRankPrevious
 */
export interface ResultAssignmentsInnerRankPrevious {
  /**
   * 採点前の段級位ID
   * @type {number}
   * @memberof ResultAssignmentsInnerRankPrevious
   */
  id?: number;
  /**
   * 段級位名
   * @type {string}
   * @memberof ResultAssignmentsInnerRankPrevious
   */
  name?: string;
}
/**
 *
 * @export
 * @interface ResultAssignmentsInnerScoresInner
 */
export interface ResultAssignmentsInnerScoresInner {
  /**
   *
   * @type {ResultAssignmentsInnerScoresInnerViewpoint}
   * @memberof ResultAssignmentsInnerScoresInner
   */
  viewpoint?: ResultAssignmentsInnerScoresInnerViewpoint;
  /**
   * 評価項目に対する点数
   * @type {number}
   * @memberof ResultAssignmentsInnerScoresInner
   */
  point?: number;
}
/**
 *
 * @export
 * @interface ResultAssignmentsInnerScoresInnerViewpoint
 */
export interface ResultAssignmentsInnerScoresInnerViewpoint {
  /**
   * 評価項目ID
   * @type {number}
   * @memberof ResultAssignmentsInnerScoresInnerViewpoint
   */
  id?: number;
  /**
   * 評価項目名
   * @type {string}
   * @memberof ResultAssignmentsInnerScoresInnerViewpoint
   */
  name?: string;
}
/**
 *
 * @export
 * @interface ResultOverallRank
 */
export interface ResultOverallRank {
  /**
   *
   * @type {ResultOverallRankCurrent}
   * @memberof ResultOverallRank
   */
  current?: ResultOverallRankCurrent;
  /**
   *
   * @type {ResultOverallRankPrevious}
   * @memberof ResultOverallRank
   */
  previous?: ResultOverallRankPrevious;
}
/**
 * 採点後の総合段級位
 * @export
 * @interface ResultOverallRankCurrent
 */
export interface ResultOverallRankCurrent {
  /**
   * 採点後の総合段級位ID
   * @type {number}
   * @memberof ResultOverallRankCurrent
   */
  id?: number;
  /**
   * 段級位名
   * @type {string}
   * @memberof ResultOverallRankCurrent
   */
  name?: string;
}
/**
 * 1つ前の段級位
 * @export
 * @interface ResultOverallRankPrevious
 */
export interface ResultOverallRankPrevious {
  /**
   * 1つ前の段級位ID
   * @type {number}
   * @memberof ResultOverallRankPrevious
   */
  id?: number;
  /**
   * 段級位名
   * @type {string}
   * @memberof ResultOverallRankPrevious
   */
  name?: string;
}
/**
 *
 * @export
 * @interface ResultUser
 */
export interface ResultUser {
  /**
   *
   * @type {string}
   * @memberof ResultUser
   */
  nickname?: string;
  /**
   *
   * @type {string}
   * @memberof ResultUser
   */
  birthday?: string;
}
/**
 *
 * @export
 * @interface Results
 */
export interface Results {
  /**
   * 同課題&同位順位の総数
   * @type {number}
   * @memberof Results
   */
  total?: number;
  /**
   *
   * @type {Array<ResultsItemsInner>}
   * @memberof Results
   */
  items?: Array<ResultsItemsInner>;
}
/**
 *
 * @export
 * @interface ResultsAmount
 */
export interface ResultsAmount {
  /**
   * ユーザーの段級位を表現する数字 (10級 → 1, ~, 師範 → 21) ※未提出であればnull
   * @type {number}
   * @memberof ResultsAmount
   */
  user_rank_order?: number | null;
  /**
   * 段級位ごとの人数に関する配列 ※段級位が低い順(ranks.order降順)
   * @type {Array<ResultsAmountRankInner>}
   * @memberof ResultsAmount
   */
  rank?: Array<ResultsAmountRankInner>;
  /**
   *
   * @type {ResultsAmountAmount}
   * @memberof ResultsAmount
   */
  amount?: ResultsAmountAmount;
}
/**
 *
 * @export
 * @interface ResultsAmountAmount
 */
export interface ResultsAmountAmount {
  /**
   * 師範・準師範の段級位取得者数
   * @type {number}
   * @memberof ResultsAmountAmount
   */
  shihan?: number;
  /**
   * 級 取得者数 (10級~1級)
   * @type {number}
   * @memberof ResultsAmountAmount
   */
  kyu?: number;
  /**
   * 段 取得者数 (初段~9段)
   * @type {number}
   * @memberof ResultsAmountAmount
   */
  dan?: number;
}
/**
 *
 * @export
 * @interface ResultsAmountRankInner
 */
export interface ResultsAmountRankInner {
  /**
   * 段級位ID
   * @type {number}
   * @memberof ResultsAmountRankInner
   */
  id?: number;
  /**
   * 段級位を表現する数字 (10級 → 1, ~, 師範 → 21)
   * @type {number}
   * @memberof ResultsAmountRankInner
   */
  order?: number;
  /**
   * 該当段級位の人数 (書体であれば、課題提出者のみ数に含む、総合段級位は全未提出者を数に含める)
   * @type {number}
   * @memberof ResultsAmountRankInner
   */
  amount?: number;
}
/**
 *
 * @export
 * @interface ResultsItemsInner
 */
export interface ResultsItemsInner {
  /**
   *
   * @type {ResultsItemsInnerUser}
   * @memberof ResultsItemsInner
   */
  user?: ResultsItemsInnerUser;
  /**
   *
   * @type {ResultsItemsInnerFont}
   * @memberof ResultsItemsInner
   */
  font?: ResultsItemsInnerFont;
  /**
   *
   * @type {ResultsItemsInnerRankOrders}
   * @memberof ResultsItemsInner
   */
  rank_orders?: ResultsItemsInnerRankOrders;
  /**
   * scoresの合計point
   * @type {number}
   * @memberof ResultsItemsInner
   */
  score_amount?: number;
  /**
   * 課題の画像URL
   * @type {string}
   * @memberof ResultsItemsInner
   */
  image_url?: string;
  /**
   * 審査所感
   * @type {Array<string>}
   * @memberof ResultsItemsInner
   */
  comments?: Array<string>;
  /**
   *
   * @type {ResultsItemsInnerRank}
   * @memberof ResultsItemsInner
   */
  rank?: ResultsItemsInnerRank;
  /**
   *
   * @type {Array<ResultsItemsInnerScoresInner>}
   * @memberof ResultsItemsInner
   */
  scores?: Array<ResultsItemsInnerScoresInner>;
}
/**
 * 書体
 * @export
 * @interface ResultsItemsInnerFont
 */
export interface ResultsItemsInnerFont {
  /**
   * 書体ID
   * @type {number}
   * @memberof ResultsItemsInnerFont
   */
  id?: number;
  /**
   * 書体名
   * @type {string}
   * @memberof ResultsItemsInnerFont
   */
  name?: string;
}
/**
 *
 * @export
 * @interface ResultsItemsInnerRank
 */
export interface ResultsItemsInnerRank {
  /**
   *
   * @type {ResultsItemsInnerRankCurrent}
   * @memberof ResultsItemsInnerRank
   */
  current?: ResultsItemsInnerRankCurrent;
  /**
   *
   * @type {boolean}
   * @memberof ResultsItemsInnerRank
   */
  is_rank_up?: boolean;
}
/**
 *
 * @export
 * @interface ResultsItemsInnerRankCurrent
 */
export interface ResultsItemsInnerRankCurrent {
  /**
   * 段級位ID
   * @type {number}
   * @memberof ResultsItemsInnerRankCurrent
   */
  id?: number;
  /**
   * 段級位名
   * @type {string}
   * @memberof ResultsItemsInnerRankCurrent
   */
  name?: string;
}
/**
 * 順位の配列
 * @export
 * @interface ResultsItemsInnerRankOrders
 */
export interface ResultsItemsInnerRankOrders {
  /**
   *
   * @type {ResultsItemsInnerRankOrdersSameRankOrder}
   * @memberof ResultsItemsInnerRankOrders
   */
  same_rank_order?: ResultsItemsInnerRankOrdersSameRankOrder;
  /**
   *
   * @type {ResultsItemsInnerRankOrdersWholeOrder}
   * @memberof ResultsItemsInnerRankOrders
   */
  whole_order?: ResultsItemsInnerRankOrdersWholeOrder;
}
/**
 * 同位の総数と同位内での課題の順位
 * @export
 * @interface ResultsItemsInnerRankOrdersSameRankOrder
 */
export interface ResultsItemsInnerRankOrdersSameRankOrder {
  /**
   * 同位順位
   * @type {number}
   * @memberof ResultsItemsInnerRankOrdersSameRankOrder
   */
  order?: number;
  /**
   * 同一提出課題総数
   * @type {number}
   * @memberof ResultsItemsInnerRankOrdersSameRankOrder
   */
  total?: number;
}
/**
 * 全体の総数と全体内での課題の順位
 * @export
 * @interface ResultsItemsInnerRankOrdersWholeOrder
 */
export interface ResultsItemsInnerRankOrdersWholeOrder {
  /**
   * 同位順位
   * @type {number}
   * @memberof ResultsItemsInnerRankOrdersWholeOrder
   */
  order?: number;
  /**
   * 提出課題総数
   * @type {number}
   * @memberof ResultsItemsInnerRankOrdersWholeOrder
   */
  total?: number;
}
/**
 *
 * @export
 * @interface ResultsItemsInnerScoresInner
 */
export interface ResultsItemsInnerScoresInner {
  /**
   *
   * @type {ResultsItemsInnerScoresInnerViewpoint}
   * @memberof ResultsItemsInnerScoresInner
   */
  viewpoint?: ResultsItemsInnerScoresInnerViewpoint;
  /**
   * 得点
   * @type {number}
   * @memberof ResultsItemsInnerScoresInner
   */
  point?: number;
}
/**
 *
 * @export
 * @interface ResultsItemsInnerScoresInnerViewpoint
 */
export interface ResultsItemsInnerScoresInnerViewpoint {
  /**
   * 項目ID
   * @type {number}
   * @memberof ResultsItemsInnerScoresInnerViewpoint
   */
  id?: number;
  /**
   * 項目名
   * @type {string}
   * @memberof ResultsItemsInnerScoresInnerViewpoint
   */
  name?: string;
}
/**
 *
 * @export
 * @interface ResultsItemsInnerUser
 */
export interface ResultsItemsInnerUser {
  /**
   * ユーザーを識別するID
   * @type {string}
   * @memberof ResultsItemsInnerUser
   */
  id?: string;
  /**
   * ニックネーム
   * @type {string}
   * @memberof ResultsItemsInnerUser
   */
  name?: string;
}
/**
 *
 * @export
 * @interface Review
 */
export interface Review {
  /**
   * 採点項目の点数の配列
   * @type {Array<CreateReviewScoresInner>}
   * @memberof Review
   */
  scores?: Array<CreateReviewScoresInner>;
  /**
   *
   * @type {ReviewRankOrders}
   * @memberof Review
   */
  rank_orders?: ReviewRankOrders;
  /**
   *
   * @type {ReviewRanks}
   * @memberof Review
   */
  ranks?: ReviewRanks;
  /**
   *
   * @type {Array<ReviewTemplateCommentsInner>}
   * @memberof Review
   */
  template_comments?: Array<ReviewTemplateCommentsInner>;
  /**
   * 自由入力の審査所感
   * @type {string}
   * @memberof Review
   */
  free_comment?: string;
  /**
   *
   * @type {ReviewScorers}
   * @memberof Review
   */
  scorers?: ReviewScorers;
}
/**
 * 順位の配列
 * @export
 * @interface ReviewRankOrders
 */
export interface ReviewRankOrders {
  /**
   *
   * @type {ReviewRankOrdersSameRankOrder}
   * @memberof ReviewRankOrders
   */
  same_rank_order?: ReviewRankOrdersSameRankOrder;
  /**
   *
   * @type {ReviewRankOrdersWholeOrder}
   * @memberof ReviewRankOrders
   */
  whole_order?: ReviewRankOrdersWholeOrder;
}
/**
 * 同位の総数と同位内での課題の順位
 * @export
 * @interface ReviewRankOrdersSameRankOrder
 */
export interface ReviewRankOrdersSameRankOrder {
  /**
   * 同位順位
   * @type {number}
   * @memberof ReviewRankOrdersSameRankOrder
   */
  order?: number;
  /**
   * 同一採点済み総数
   * @type {number}
   * @memberof ReviewRankOrdersSameRankOrder
   */
  total?: number;
}
/**
 * 全体の総数と全体内での課題の順位
 * @export
 * @interface ReviewRankOrdersWholeOrder
 */
export interface ReviewRankOrdersWholeOrder {
  /**
   * 同位順位
   * @type {number}
   * @memberof ReviewRankOrdersWholeOrder
   */
  order?: number;
  /**
   * 採点済み総数
   * @type {number}
   * @memberof ReviewRankOrdersWholeOrder
   */
  total?: number;
}
/**
 * 採点する書体段級位の前回
 * @export
 * @interface ReviewRanks
 */
export interface ReviewRanks {
  /**
   * 昇級判断前の書体段級位
   * @type {string}
   * @memberof ReviewRanks
   */
  current_rank?: string;
  /**
   * 昇級判断後の書体段級位
   * @type {string}
   * @memberof ReviewRanks
   */
  next_rank?: string;
}
/**
 * 採点者
 * @export
 * @interface ReviewScorers
 */
export interface ReviewScorers {
  /**
   * 最初の採点者の氏名
   * @type {string}
   * @memberof ReviewScorers
   */
  first_scorer?: string;
  /**
   * 最後に更新した採点者の氏名
   * @type {string}
   * @memberof ReviewScorers
   */
  final_scorer?: string;
}
/**
 *
 * @export
 * @interface ReviewTemplateCommentsInner
 */
export interface ReviewTemplateCommentsInner {
  /**
   * 審査所感テンプレートID
   * @type {number}
   * @memberof ReviewTemplateCommentsInner
   */
  id?: number;
  /**
   * 審査所感テンプレートカテゴリID
   * @type {number}
   * @memberof ReviewTemplateCommentsInner
   */
  category_id?: number;
}
/**
 * Stripeの顧客ID
 * @export
 * @interface StripeCustomerId
 */
export interface StripeCustomerId {
  /**
   *
   * @type {string}
   * @memberof StripeCustomerId
   */
  stripe_customer_id?: string;
}
/**
 * プラン契約のステータス
 * @export
 * @interface SubscriptionStatus
 */
export interface SubscriptionStatus {
  /**
   * subscription_statusの意味: active: 継続中 past_due: 支払い遅延中 cancel: サブスク解約 waiting_cancel: 解約待ち 翌24日23:59に解約 unpaid: 未払い trial: トライアル期間中 trial_expired: トライアル終了
   * @type {string}
   * @memberof SubscriptionStatus
   */
  subscription_status?: SubscriptionStatusSubscriptionStatusEnum;
}

export const SubscriptionStatusSubscriptionStatusEnum = {
  Active: 'active',
  PastDue: 'past_due',
  Cancel: 'cancel',
  WaitingCancel: 'waiting_cancel',
  Unpaid: 'unpaid',
  Trial: 'trial',
  TrialExpired: 'trial_expired',
} as const;

export type SubscriptionStatusSubscriptionStatusEnum =
  (typeof SubscriptionStatusSubscriptionStatusEnum)[keyof typeof SubscriptionStatusSubscriptionStatusEnum];

/**
 * トライアル終了日
 * @export
 * @interface TrialEndDate
 */
export interface TrialEndDate {
  /**
   *
   * @type {string}
   * @memberof TrialEndDate
   */
  trial_end_date?: string;
}
/**
 *
 * @export
 * @interface UpdateAssignmentRequest
 */
export interface UpdateAssignmentRequest {
  /**
   *
   * @type {number}
   * @memberof UpdateAssignmentRequest
   */
  year?: number;
  /**
   *
   * @type {number}
   * @memberof UpdateAssignmentRequest
   */
  month?: number;
  /**
   *
   * @type {File}
   * @memberof UpdateAssignmentRequest
   */
  file?: File;
}
/**
 *
 * @export
 * @interface UpdateHandbook
 */
export interface UpdateHandbook {
  /**
   *
   * @type {string}
   * @memberof UpdateHandbook
   */
  theme?: string | null;
  /**
   *
   * @type {UpdateHandbookDemonstration}
   * @memberof UpdateHandbook
   */
  demonstration: UpdateHandbookDemonstration;
  /**
   *
   * @type {UpdateHandbookExplanation}
   * @memberof UpdateHandbook
   */
  explanation: UpdateHandbookExplanation;
}
/**
 *
 * @export
 * @interface UpdateHandbookDemonstration
 */
export interface UpdateHandbookDemonstration {
  /**
   *
   * @type {Array<File>}
   * @memberof UpdateHandbookDemonstration
   */
  videos: Array<File>;
  /**
   *
   * @type {Array<File>}
   * @memberof UpdateHandbookDemonstration
   */
  images: Array<File>;
  /**
   *
   * @type {string}
   * @memberof UpdateHandbookDemonstration
   */
  comment: string | null;
}
/**
 *
 * @export
 * @interface UpdateHandbookExplanation
 */
export interface UpdateHandbookExplanation {
  /**
   *
   * @type {Array<File>}
   * @memberof UpdateHandbookExplanation
   */
  videos: Array<File>;
  /**
   *
   * @type {Array<File>}
   * @memberof UpdateHandbookExplanation
   */
  images: Array<File>;
  /**
   *
   * @type {string}
   * @memberof UpdateHandbookExplanation
   */
  comment: string | null;
}
/**
 * 支払い方法を更新する際にリクエストに含める情報
 * @export
 * @interface UpdatePaymentMethodParams
 */
export interface UpdatePaymentMethodParams {
  /**
   *
   * @type {string}
   * @memberof UpdatePaymentMethodParams
   */
  payment_method_id: string;
}
/**
 * 更新する際にリクエストに含めるユーザー情報
 * @export
 * @interface UpdateUser
 */
export interface UpdateUser {
  /**
   *
   * @type {Array<UserBaseRolesInner>}
   * @memberof UpdateUser
   */
  roles?: Array<UserBaseRolesInner>;
  /**
   *
   * @type {string}
   * @memberof UpdateUser
   */
  last_name: string;
  /**
   *
   * @type {string}
   * @memberof UpdateUser
   */
  first_name: string;
  /**
   *
   * @type {string}
   * @memberof UpdateUser
   */
  nickname: string;
  /**
   *
   * @type {number}
   * @memberof UpdateUser
   */
  course_type: number;
  /**
   *
   * @type {string}
   * @memberof UpdateUser
   */
  email: string;
  /**
   *
   * @type {number}
   * @memberof UpdateUser
   */
  gender_id: number;
  /**
   *
   * @type {string}
   * @memberof UpdateUser
   */
  birthday: string;
  /**
   *
   * @type {string}
   * @memberof UpdateUser
   */
  prefecture: string;
  /**
   *
   * @type {boolean}
   * @memberof UpdateUser
   */
  is_experienced: boolean;
  /**
   *
   * @type {string}
   * @memberof UpdateUser
   */
  parent_last_name?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateUser
   */
  parent_first_name?: string;
  /**
   *
   * @type {UserBaseCreditCard}
   * @memberof UpdateUser
   */
  credit_card?: UserBaseCreditCard;
  /**
   *
   * @type {boolean}
   * @memberof UpdateUser
   */
  is_subscribed?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof UpdateUser
   */
  completed_sign_up?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof UpdateUser
   */
  is_private?: boolean;
  /**
   *
   * @type {string}
   * @memberof UpdateUser
   */
  trial_end_date?: string;
  /**
   * subscription_statusの意味: active: 継続中 past_due: 支払い遅延中 cancel: サブスク解約 waiting_cancel: 解約待ち 翌24日23:59に解約 unpaid: 未払い trial: トライアル期間中 trial_expired: トライアル終了
   * @type {string}
   * @memberof UpdateUser
   */
  subscription_status?: UpdateUserSubscriptionStatusEnum;
}

export const UpdateUserSubscriptionStatusEnum = {
  Active: 'active',
  PastDue: 'past_due',
  Cancel: 'cancel',
  WaitingCancel: 'waiting_cancel',
  Unpaid: 'unpaid',
  Trial: 'trial',
  TrialExpired: 'trial_expired',
} as const;

export type UpdateUserSubscriptionStatusEnum =
  (typeof UpdateUserSubscriptionStatusEnum)[keyof typeof UpdateUserSubscriptionStatusEnum];

/**
 * レスポンスで返すユーザー情報
 * @export
 * @interface User
 */
export interface User {
  /**
   *
   * @type {Array<UserBaseRolesInner>}
   * @memberof User
   */
  roles?: Array<UserBaseRolesInner>;
  /**
   *
   * @type {string}
   * @memberof User
   */
  last_name: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  first_name: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  nickname: string;
  /**
   *
   * @type {number}
   * @memberof User
   */
  course_type: number;
  /**
   *
   * @type {string}
   * @memberof User
   */
  email: string;
  /**
   *
   * @type {number}
   * @memberof User
   */
  gender_id: number;
  /**
   *
   * @type {string}
   * @memberof User
   */
  birthday: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  prefecture: string;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  is_experienced: boolean;
  /**
   *
   * @type {string}
   * @memberof User
   */
  parent_last_name?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  parent_first_name?: string;
  /**
   *
   * @type {UserBaseCreditCard}
   * @memberof User
   */
  credit_card?: UserBaseCreditCard;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  is_subscribed?: boolean;
  /**
   *
   * @type {string}
   * @memberof User
   */
  id: string;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  is_private?: boolean;
  /**
   *
   * @type {string}
   * @memberof User
   */
  trial_end_date?: string;
  /**
   * subscription_statusの意味: active: 継続中 past_due: 支払い遅延中 cancel: サブスク解約 waiting_cancel: 解約待ち 翌24日23:59に解約 unpaid: 未払い trial: トライアル期間中 trial_expired: トライアル終了
   * @type {string}
   * @memberof User
   */
  subscription_status?: UserSubscriptionStatusEnum;
}

export const UserSubscriptionStatusEnum = {
  Active: 'active',
  PastDue: 'past_due',
  Cancel: 'cancel',
  WaitingCancel: 'waiting_cancel',
  Unpaid: 'unpaid',
  Trial: 'trial',
  TrialExpired: 'trial_expired',
} as const;

export type UserSubscriptionStatusEnum =
  (typeof UserSubscriptionStatusEnum)[keyof typeof UserSubscriptionStatusEnum];

/**
 * ユーザー関連スキーマの共通部分
 * @export
 * @interface UserBase
 */
export interface UserBase {
  /**
   *
   * @type {Array<UserBaseRolesInner>}
   * @memberof UserBase
   */
  roles?: Array<UserBaseRolesInner>;
  /**
   *
   * @type {string}
   * @memberof UserBase
   */
  last_name: string;
  /**
   *
   * @type {string}
   * @memberof UserBase
   */
  first_name: string;
  /**
   *
   * @type {string}
   * @memberof UserBase
   */
  nickname: string;
  /**
   *
   * @type {number}
   * @memberof UserBase
   */
  course_type: number;
  /**
   *
   * @type {string}
   * @memberof UserBase
   */
  email: string;
  /**
   *
   * @type {number}
   * @memberof UserBase
   */
  gender_id: number;
  /**
   *
   * @type {string}
   * @memberof UserBase
   */
  birthday: string;
  /**
   *
   * @type {string}
   * @memberof UserBase
   */
  prefecture: string;
  /**
   *
   * @type {boolean}
   * @memberof UserBase
   */
  is_experienced: boolean;
  /**
   *
   * @type {string}
   * @memberof UserBase
   */
  parent_last_name?: string;
  /**
   *
   * @type {string}
   * @memberof UserBase
   */
  parent_first_name?: string;
  /**
   *
   * @type {UserBaseCreditCard}
   * @memberof UserBase
   */
  credit_card?: UserBaseCreditCard;
  /**
   *
   * @type {boolean}
   * @memberof UserBase
   */
  is_subscribed?: boolean;
}
/**
 *
 * @export
 * @interface UserBaseCreditCard
 */
export interface UserBaseCreditCard {
  /**
   *
   * @type {number}
   * @memberof UserBaseCreditCard
   */
  expired_year?: number;
  /**
   *
   * @type {number}
   * @memberof UserBaseCreditCard
   */
  expired_month?: number;
  /**
   *
   * @type {string}
   * @memberof UserBaseCreditCard
   */
  brand?: string;
  /**
   *
   * @type {number}
   * @memberof UserBaseCreditCard
   */
  num?: number;
}
/**
 *
 * @export
 * @interface UserBaseRolesInner
 */
export interface UserBaseRolesInner {
  /**
   *
   * @type {string}
   * @memberof UserBaseRolesInner
   */
  role_name?: string;
  /**
   *
   * @type {string}
   * @memberof UserBaseRolesInner
   */
  display_name?: string;
}
/**
 * ユーザーのUUID
 * @export
 * @interface UserId
 */
export interface UserId {
  /**
   *
   * @type {string}
   * @memberof UserId
   */
  id?: string;
}
/**
 *
 * @export
 * @interface UserWithRanks
 */
export interface UserWithRanks {
  /**
   *
   * @type {string}
   * @memberof UserWithRanks
   */
  user_id?: string;
  /**
   *
   * @type {string}
   * @memberof UserWithRanks
   */
  nickname?: string;
  /**
   *
   * @type {number}
   * @memberof UserWithRanks
   */
  course_type?: number;
  /**
   *
   * @type {string}
   * @memberof UserWithRanks
   */
  student_grade?: string;
  /**
   *
   * @type {Array<RankWithFont>}
   * @memberof UserWithRanks
   */
  ranks?: Array<RankWithFont>;
}
/**
 * ログインユーザーの情報
 * @export
 * @interface Userinfo
 */
export interface Userinfo {
  /**
   *
   * @type {Array<UserBaseRolesInner>}
   * @memberof Userinfo
   */
  roles?: Array<UserBaseRolesInner>;
  /**
   *
   * @type {string}
   * @memberof Userinfo
   */
  last_name: string;
  /**
   *
   * @type {string}
   * @memberof Userinfo
   */
  first_name: string;
  /**
   *
   * @type {string}
   * @memberof Userinfo
   */
  nickname: string;
  /**
   *
   * @type {number}
   * @memberof Userinfo
   */
  course_type: number;
  /**
   *
   * @type {string}
   * @memberof Userinfo
   */
  email: string;
  /**
   *
   * @type {number}
   * @memberof Userinfo
   */
  gender_id: number;
  /**
   *
   * @type {string}
   * @memberof Userinfo
   */
  birthday: string;
  /**
   *
   * @type {string}
   * @memberof Userinfo
   */
  prefecture: string;
  /**
   *
   * @type {boolean}
   * @memberof Userinfo
   */
  is_experienced: boolean;
  /**
   *
   * @type {string}
   * @memberof Userinfo
   */
  parent_last_name?: string;
  /**
   *
   * @type {string}
   * @memberof Userinfo
   */
  parent_first_name?: string;
  /**
   *
   * @type {UserBaseCreditCard}
   * @memberof Userinfo
   */
  credit_card?: UserBaseCreditCard;
  /**
   *
   * @type {boolean}
   * @memberof Userinfo
   */
  is_subscribed?: boolean;
  /**
   *
   * @type {string}
   * @memberof Userinfo
   */
  id: string;
  /**
   *
   * @type {boolean}
   * @memberof Userinfo
   */
  completed_sign_up: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Userinfo
   */
  is_private?: boolean;
  /**
   *
   * @type {string}
   * @memberof Userinfo
   */
  trial_end_date?: string;
  /**
   * subscription_statusの意味: active: 継続中 past_due: 支払い遅延中 cancel: サブスク解約 waiting_cancel: 解約待ち 翌24日23:59に解約 unpaid: 未払い trial: トライアル期間中 trial_expired: トライアル終了
   * @type {string}
   * @memberof Userinfo
   */
  subscription_status?: UserinfoSubscriptionStatusEnum;
  /**
   *
   * @type {string}
   * @memberof Userinfo
   */
  stripe_customer_id?: string;
}

export const UserinfoSubscriptionStatusEnum = {
  Active: 'active',
  PastDue: 'past_due',
  Cancel: 'cancel',
  WaitingCancel: 'waiting_cancel',
  Unpaid: 'unpaid',
  Trial: 'trial',
  TrialExpired: 'trial_expired',
} as const;

export type UserinfoSubscriptionStatusEnum =
  (typeof UserinfoSubscriptionStatusEnum)[keyof typeof UserinfoSubscriptionStatusEnum];

/**
 *
 * @export
 * @interface Viewpoint
 */
export interface Viewpoint {
  /**
   * 審査項目ID
   * @type {number}
   * @memberof Viewpoint
   */
  id?: number;
  /**
   * 審査項目の名称
   * @type {string}
   * @memberof Viewpoint
   */
  name?: string;
}

/**
 * AssignmentApi - axios parameter creator
 * @export
 */
export const AssignmentApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * 特定の課題の審査情報を新規作成
     * @param {number} id 課題管理で課題を識別するID
     * @param {CreateReview} createReview
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAssignmentReview: async (
      id: number,
      createReview: CreateReview,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('createAssignmentReview', 'id', id);
      // verify required parameter 'createReview' is not null or undefined
      assertParamExists('createAssignmentReview', 'createReview', createReview);
      const localVarPath = `/assignments/{id}/review`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createReview,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * 課題削除
     * @param {number} id 課題管理で課題を識別するID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAssignment: async (
      id: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteAssignment', 'id', id);
      const localVarPath = `/assignments/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * 課題情報を1件取得
     * @param {number} id 課題管理で課題を識別するID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAssignment: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getAssignment', 'id', id);
      const localVarPath = `/assignments/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * 課題管理画面で使用、年月で絞って課題提出者数と書体ごとの提出課題数を一覧で取得
     * @param {number} year 年を指定する
     * @param {number} month 月を指定する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAssignmentCount: async (
      year: number,
      month: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'year' is not null or undefined
      assertParamExists('getAssignmentCount', 'year', year);
      // verify required parameter 'month' is not null or undefined
      assertParamExists('getAssignmentCount', 'month', month);
      const localVarPath = `/assignments/count`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (year !== undefined) {
        localVarQueryParameter['year'] = year;
      }

      if (month !== undefined) {
        localVarQueryParameter['month'] = month;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * 特定の課題の審査情報を取得
     * @param {number} id 課題管理で課題を識別するID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAssignmentReview: async (
      id: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getAssignmentReview', 'id', id);
      const localVarPath = `/assignments/{id}/review`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * 渡されてきた年と月が運用スケジュールのどの期間に該当するか判断
     * @param {number} [year] 年を指定する
     * @param {number} [month] 月を指定する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAssignmentTermStatus: async (
      year?: number,
      month?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/assignments/term-status`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (year !== undefined) {
        localVarQueryParameter['year'] = year;
      }

      if (month !== undefined) {
        localVarQueryParameter['month'] = month;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * 課題提出画面で使用、提出済みの課題を年月とユーザーIDで絞り込んで該当する課題情報を返す
     * @param {number} year 年を指定する
     * @param {number} month 月を指定する
     * @param {string} userId ユーザーを識別するID, クエリパラメータで使用
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAssignments: async (
      year: number,
      month: number,
      userId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'year' is not null or undefined
      assertParamExists('getAssignments', 'year', year);
      // verify required parameter 'month' is not null or undefined
      assertParamExists('getAssignments', 'month', month);
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('getAssignments', 'userId', userId);
      const localVarPath = `/assignments`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (year !== undefined) {
        localVarQueryParameter['year'] = year;
      }

      if (month !== undefined) {
        localVarQueryParameter['month'] = month;
      }

      if (userId !== undefined) {
        localVarQueryParameter['user_id'] = userId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * 課題管理画面中列で使用、手本IDで絞って提出された課題のIDと提出した人のユーザー名を一覧で取得
     * @param {number} handbookId 手本ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAssignmentsUsername: async (
      handbookId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'handbookId' is not null or undefined
      assertParamExists('getAssignmentsUsername', 'handbookId', handbookId);
      const localVarPath = `/assignments/users`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (handbookId !== undefined) {
        localVarQueryParameter['handbook_id'] = handbookId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * 個人のその月の提出課題を1件追加
     * @param {PostAssignmentRequest} postAssignmentRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postAssignment: async (
      postAssignmentRequest: PostAssignmentRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'postAssignmentRequest' is not null or undefined
      assertParamExists('postAssignment', 'postAssignmentRequest', postAssignmentRequest);
      const localVarPath = `/assignments`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        postAssignmentRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * 課題を１件更新
     * @param {number} id 課題管理で課題を識別するID
     * @param {UpdateAssignmentRequest} updateAssignmentRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAssignment: async (
      id: number,
      updateAssignmentRequest: UpdateAssignmentRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateAssignment', 'id', id);
      // verify required parameter 'updateAssignmentRequest' is not null or undefined
      assertParamExists('updateAssignment', 'updateAssignmentRequest', updateAssignmentRequest);
      const localVarPath = `/assignments/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateAssignmentRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AssignmentApi - functional programming interface
 * @export
 */
export const AssignmentApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AssignmentApiAxiosParamCreator(configuration);
  return {
    /**
     * 特定の課題の審査情報を新規作成
     * @param {number} id 課題管理で課題を識別するID
     * @param {CreateReview} createReview
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createAssignmentReview(
      id: number,
      createReview: CreateReview,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Review>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createAssignmentReview(
        id,
        createReview,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * 課題削除
     * @param {number} id 課題管理で課題を識別するID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteAssignment(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAssignment(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * 課題情報を1件取得
     * @param {number} id 課題管理で課題を識別するID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAssignment(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Assignment>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAssignment(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * 課題管理画面で使用、年月で絞って課題提出者数と書体ごとの提出課題数を一覧で取得
     * @param {number} year 年を指定する
     * @param {number} month 月を指定する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAssignmentCount(
      year: number,
      month: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssignmentCount>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAssignmentCount(
        year,
        month,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * 特定の課題の審査情報を取得
     * @param {number} id 課題管理で課題を識別するID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAssignmentReview(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Review>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAssignmentReview(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * 渡されてきた年と月が運用スケジュールのどの期間に該当するか判断
     * @param {number} [year] 年を指定する
     * @param {number} [month] 月を指定する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAssignmentTermStatus(
      year?: number,
      month?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAssignmentTermStatus(
        year,
        month,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * 課題提出画面で使用、提出済みの課題を年月とユーザーIDで絞り込んで該当する課題情報を返す
     * @param {number} year 年を指定する
     * @param {number} month 月を指定する
     * @param {string} userId ユーザーを識別するID, クエリパラメータで使用
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAssignments(
      year: number,
      month: number,
      userId: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<GetAssignments200ResponseInner>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAssignments(
        year,
        month,
        userId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * 課題管理画面中列で使用、手本IDで絞って提出された課題のIDと提出した人のユーザー名を一覧で取得
     * @param {number} handbookId 手本ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAssignmentsUsername(
      handbookId: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<Array<GetAssignmentsUsername200ResponseInner>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAssignmentsUsername(
        handbookId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * 個人のその月の提出課題を1件追加
     * @param {PostAssignmentRequest} postAssignmentRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postAssignment(
      postAssignmentRequest: PostAssignmentRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Assignment>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postAssignment(
        postAssignmentRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * 課題を１件更新
     * @param {number} id 課題管理で課題を識別するID
     * @param {UpdateAssignmentRequest} updateAssignmentRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateAssignment(
      id: number,
      updateAssignmentRequest: UpdateAssignmentRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Assignment>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateAssignment(
        id,
        updateAssignmentRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * AssignmentApi - factory interface
 * @export
 */
export const AssignmentApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = AssignmentApiFp(configuration);
  return {
    /**
     * 特定の課題の審査情報を新規作成
     * @param {number} id 課題管理で課題を識別するID
     * @param {CreateReview} createReview
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAssignmentReview(
      id: number,
      createReview: CreateReview,
      options?: any
    ): AxiosPromise<Review> {
      return localVarFp
        .createAssignmentReview(id, createReview, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * 課題削除
     * @param {number} id 課題管理で課題を識別するID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAssignment(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.deleteAssignment(id, options).then((request) => request(axios, basePath));
    },
    /**
     * 課題情報を1件取得
     * @param {number} id 課題管理で課題を識別するID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAssignment(id: number, options?: any): AxiosPromise<Assignment> {
      return localVarFp.getAssignment(id, options).then((request) => request(axios, basePath));
    },
    /**
     * 課題管理画面で使用、年月で絞って課題提出者数と書体ごとの提出課題数を一覧で取得
     * @param {number} year 年を指定する
     * @param {number} month 月を指定する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAssignmentCount(year: number, month: number, options?: any): AxiosPromise<AssignmentCount> {
      return localVarFp
        .getAssignmentCount(year, month, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * 特定の課題の審査情報を取得
     * @param {number} id 課題管理で課題を識別するID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAssignmentReview(id: number, options?: any): AxiosPromise<Review> {
      return localVarFp
        .getAssignmentReview(id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * 渡されてきた年と月が運用スケジュールのどの期間に該当するか判断
     * @param {number} [year] 年を指定する
     * @param {number} [month] 月を指定する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAssignmentTermStatus(year?: number, month?: number, options?: any): AxiosPromise<number> {
      return localVarFp
        .getAssignmentTermStatus(year, month, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * 課題提出画面で使用、提出済みの課題を年月とユーザーIDで絞り込んで該当する課題情報を返す
     * @param {number} year 年を指定する
     * @param {number} month 月を指定する
     * @param {string} userId ユーザーを識別するID, クエリパラメータで使用
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAssignments(
      year: number,
      month: number,
      userId: string,
      options?: any
    ): AxiosPromise<Array<GetAssignments200ResponseInner>> {
      return localVarFp
        .getAssignments(year, month, userId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * 課題管理画面中列で使用、手本IDで絞って提出された課題のIDと提出した人のユーザー名を一覧で取得
     * @param {number} handbookId 手本ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAssignmentsUsername(
      handbookId: number,
      options?: any
    ): AxiosPromise<Array<GetAssignmentsUsername200ResponseInner>> {
      return localVarFp
        .getAssignmentsUsername(handbookId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * 個人のその月の提出課題を1件追加
     * @param {PostAssignmentRequest} postAssignmentRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postAssignment(
      postAssignmentRequest: PostAssignmentRequest,
      options?: any
    ): AxiosPromise<Assignment> {
      return localVarFp
        .postAssignment(postAssignmentRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * 課題を１件更新
     * @param {number} id 課題管理で課題を識別するID
     * @param {UpdateAssignmentRequest} updateAssignmentRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateAssignment(
      id: number,
      updateAssignmentRequest: UpdateAssignmentRequest,
      options?: any
    ): AxiosPromise<Assignment> {
      return localVarFp
        .updateAssignment(id, updateAssignmentRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * AssignmentApi - object-oriented interface
 * @export
 * @class AssignmentApi
 * @extends {BaseAPI}
 */
export class AssignmentApi extends BaseAPI {
  /**
   * 特定の課題の審査情報を新規作成
   * @param {number} id 課題管理で課題を識別するID
   * @param {CreateReview} createReview
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssignmentApi
   */
  public createAssignmentReview(
    id: number,
    createReview: CreateReview,
    options?: AxiosRequestConfig
  ) {
    return AssignmentApiFp(this.configuration)
      .createAssignmentReview(id, createReview, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * 課題削除
   * @param {number} id 課題管理で課題を識別するID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssignmentApi
   */
  public deleteAssignment(id: number, options?: AxiosRequestConfig) {
    return AssignmentApiFp(this.configuration)
      .deleteAssignment(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * 課題情報を1件取得
   * @param {number} id 課題管理で課題を識別するID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssignmentApi
   */
  public getAssignment(id: number, options?: AxiosRequestConfig) {
    return AssignmentApiFp(this.configuration)
      .getAssignment(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * 課題管理画面で使用、年月で絞って課題提出者数と書体ごとの提出課題数を一覧で取得
   * @param {number} year 年を指定する
   * @param {number} month 月を指定する
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssignmentApi
   */
  public getAssignmentCount(year: number, month: number, options?: AxiosRequestConfig) {
    return AssignmentApiFp(this.configuration)
      .getAssignmentCount(year, month, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * 特定の課題の審査情報を取得
   * @param {number} id 課題管理で課題を識別するID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssignmentApi
   */
  public getAssignmentReview(id: number, options?: AxiosRequestConfig) {
    return AssignmentApiFp(this.configuration)
      .getAssignmentReview(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * 渡されてきた年と月が運用スケジュールのどの期間に該当するか判断
   * @param {number} [year] 年を指定する
   * @param {number} [month] 月を指定する
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssignmentApi
   */
  public getAssignmentTermStatus(year?: number, month?: number, options?: AxiosRequestConfig) {
    return AssignmentApiFp(this.configuration)
      .getAssignmentTermStatus(year, month, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * 課題提出画面で使用、提出済みの課題を年月とユーザーIDで絞り込んで該当する課題情報を返す
   * @param {number} year 年を指定する
   * @param {number} month 月を指定する
   * @param {string} userId ユーザーを識別するID, クエリパラメータで使用
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssignmentApi
   */
  public getAssignments(year: number, month: number, userId: string, options?: AxiosRequestConfig) {
    return AssignmentApiFp(this.configuration)
      .getAssignments(year, month, userId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * 課題管理画面中列で使用、手本IDで絞って提出された課題のIDと提出した人のユーザー名を一覧で取得
   * @param {number} handbookId 手本ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssignmentApi
   */
  public getAssignmentsUsername(handbookId: number, options?: AxiosRequestConfig) {
    return AssignmentApiFp(this.configuration)
      .getAssignmentsUsername(handbookId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * 個人のその月の提出課題を1件追加
   * @param {PostAssignmentRequest} postAssignmentRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssignmentApi
   */
  public postAssignment(
    postAssignmentRequest: PostAssignmentRequest,
    options?: AxiosRequestConfig
  ) {
    return AssignmentApiFp(this.configuration)
      .postAssignment(postAssignmentRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * 課題を１件更新
   * @param {number} id 課題管理で課題を識別するID
   * @param {UpdateAssignmentRequest} updateAssignmentRequest
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssignmentApi
   */
  public updateAssignment(
    id: number,
    updateAssignmentRequest: UpdateAssignmentRequest,
    options?: AxiosRequestConfig
  ) {
    return AssignmentApiFp(this.configuration)
      .updateAssignment(id, updateAssignmentRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ClassroomApi - axios parameter creator
 * @export
 */
export const ClassroomApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * 教室情報を全件取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClassrooms: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/classrooms`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * 教室情報追加
     * @param {InputClassroom} inputClassroom
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postClassroom: async (
      inputClassroom: InputClassroom,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'inputClassroom' is not null or undefined
      assertParamExists('postClassroom', 'inputClassroom', inputClassroom);
      const localVarPath = `/classrooms`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        inputClassroom,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ClassroomApi - functional programming interface
 * @export
 */
export const ClassroomApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ClassroomApiAxiosParamCreator(configuration);
  return {
    /**
     * 教室情報を全件取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getClassrooms(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Classroom>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getClassrooms(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * 教室情報追加
     * @param {InputClassroom} inputClassroom
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postClassroom(
      inputClassroom: InputClassroom,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Classroom>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postClassroom(
        inputClassroom,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ClassroomApi - factory interface
 * @export
 */
export const ClassroomApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = ClassroomApiFp(configuration);
  return {
    /**
     * 教室情報を全件取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClassrooms(options?: any): AxiosPromise<Array<Classroom>> {
      return localVarFp.getClassrooms(options).then((request) => request(axios, basePath));
    },
    /**
     * 教室情報追加
     * @param {InputClassroom} inputClassroom
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postClassroom(inputClassroom: InputClassroom, options?: any): AxiosPromise<Classroom> {
      return localVarFp
        .postClassroom(inputClassroom, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * ClassroomApi - object-oriented interface
 * @export
 * @class ClassroomApi
 * @extends {BaseAPI}
 */
export class ClassroomApi extends BaseAPI {
  /**
   * 教室情報を全件取得する
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClassroomApi
   */
  public getClassrooms(options?: AxiosRequestConfig) {
    return ClassroomApiFp(this.configuration)
      .getClassrooms(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * 教室情報追加
   * @param {InputClassroom} inputClassroom
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ClassroomApi
   */
  public postClassroom(inputClassroom: InputClassroom, options?: AxiosRequestConfig) {
    return ClassroomApiFp(this.configuration)
      .postClassroom(inputClassroom, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * CommentTemplateApi - axios parameter creator
 * @export
 */
export const CommentTemplateApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * コースタイプを指定して該当する審査所感定型文を取得する
     * @param {number} courseType コースの種別(一般,学生)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCommentTemplates: async (
      courseType: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'courseType' is not null or undefined
      assertParamExists('getCommentTemplates', 'courseType', courseType);
      const localVarPath = `/comment-templates`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (courseType !== undefined) {
        localVarQueryParameter['course_type'] = courseType;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CommentTemplateApi - functional programming interface
 * @export
 */
export const CommentTemplateApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CommentTemplateApiAxiosParamCreator(configuration);
  return {
    /**
     * コースタイプを指定して該当する審査所感定型文を取得する
     * @param {number} courseType コースの種別(一般,学生)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCommentTemplates(
      courseType: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CommentTemplate>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCommentTemplates(
        courseType,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * CommentTemplateApi - factory interface
 * @export
 */
export const CommentTemplateApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = CommentTemplateApiFp(configuration);
  return {
    /**
     * コースタイプを指定して該当する審査所感定型文を取得する
     * @param {number} courseType コースの種別(一般,学生)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCommentTemplates(courseType: number, options?: any): AxiosPromise<Array<CommentTemplate>> {
      return localVarFp
        .getCommentTemplates(courseType, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * CommentTemplateApi - object-oriented interface
 * @export
 * @class CommentTemplateApi
 * @extends {BaseAPI}
 */
export class CommentTemplateApi extends BaseAPI {
  /**
   * コースタイプを指定して該当する審査所感定型文を取得する
   * @param {number} courseType コースの種別(一般,学生)
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommentTemplateApi
   */
  public getCommentTemplates(courseType: number, options?: AxiosRequestConfig) {
    return CommentTemplateApiFp(this.configuration)
      .getCommentTemplates(courseType, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * DateApi - axios parameter creator
 * @export
 */
export const DateApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * 期限指定でAlertを表示するために月日を取得する。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDate: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/date`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DateApi - functional programming interface
 * @export
 */
export const DateApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DateApiAxiosParamCreator(configuration);
  return {
    /**
     * 期限指定でAlertを表示するために月日を取得する。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDate(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDate(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * DateApi - factory interface
 * @export
 */
export const DateApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = DateApiFp(configuration);
  return {
    /**
     * 期限指定でAlertを表示するために月日を取得する。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDate(options?: any): AxiosPromise<string> {
      return localVarFp.getDate(options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * DateApi - object-oriented interface
 * @export
 * @class DateApi
 * @extends {BaseAPI}
 */
export class DateApi extends BaseAPI {
  /**
   * 期限指定でAlertを表示するために月日を取得する。
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DateApi
   */
  public getDate(options?: AxiosRequestConfig) {
    return DateApiFp(this.configuration)
      .getDate(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * FontApi - axios parameter creator
 * @export
 */
export const FontApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * 書体情報全件取得 コースを指定するとそのコースに対応した書体を全件取得
     * @param {number} [courseType] コースの種別(一般,学生)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFonts: async (
      courseType?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/fonts`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (courseType !== undefined) {
        localVarQueryParameter['course_type'] = courseType;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * FontApi - functional programming interface
 * @export
 */
export const FontApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = FontApiAxiosParamCreator(configuration);
  return {
    /**
     * 書体情報全件取得 コースを指定するとそのコースに対応した書体を全件取得
     * @param {number} [courseType] コースの種別(一般,学生)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFonts(
      courseType?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FontsInner>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFonts(courseType, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * FontApi - factory interface
 * @export
 */
export const FontApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = FontApiFp(configuration);
  return {
    /**
     * 書体情報全件取得 コースを指定するとそのコースに対応した書体を全件取得
     * @param {number} [courseType] コースの種別(一般,学生)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFonts(courseType?: number, options?: any): AxiosPromise<Array<FontsInner>> {
      return localVarFp.getFonts(courseType, options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * FontApi - object-oriented interface
 * @export
 * @class FontApi
 * @extends {BaseAPI}
 */
export class FontApi extends BaseAPI {
  /**
   * 書体情報全件取得 コースを指定するとそのコースに対応した書体を全件取得
   * @param {number} [courseType] コースの種別(一般,学生)
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FontApi
   */
  public getFonts(courseType?: number, options?: AxiosRequestConfig) {
    return FontApiFp(this.configuration)
      .getFonts(courseType, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * HandBookApi - axios parameter creator
 * @export
 */
export const HandBookApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * 手本情報追加
     * @param {CreateHandbook} createHandbook
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createHandbook: async (
      createHandbook: CreateHandbook,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'createHandbook' is not null or undefined
      assertParamExists('createHandbook', 'createHandbook', createHandbook);
      const localVarPath = `/handbooks`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createHandbook,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * 手本削除
     * @param {number} id 手本を識別するID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteHandbook: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteHandbook', 'id', id);
      const localVarPath = `/handbooks/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * 年月と書体と学年を指定すると対応する手本情報を取得
     * @param {number} [year] 年を指定する
     * @param {number} [month] 月を指定する
     * @param {number} [fontId] 書体ID
     * @param {number} [gradeId] 学年ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHandbooks: async (
      year?: number,
      month?: number,
      fontId?: number,
      gradeId?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/handbooks`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (year !== undefined) {
        localVarQueryParameter['year'] = year;
      }

      if (month !== undefined) {
        localVarQueryParameter['month'] = month;
      }

      if (fontId !== undefined) {
        localVarQueryParameter['font_id'] = fontId;
      }

      if (gradeId !== undefined) {
        localVarQueryParameter['grade_id'] = gradeId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * 手本情報が存在する、年と月の情報を取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHandbooksAvailTerms: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/handbooks/avail-terms`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * 手本情報更新
     * @param {number} id 手本を識別するID
     * @param {UpdateHandbook} updateHandbook
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateHandbook: async (
      id: number,
      updateHandbook: UpdateHandbook,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateHandbook', 'id', id);
      // verify required parameter 'updateHandbook' is not null or undefined
      assertParamExists('updateHandbook', 'updateHandbook', updateHandbook);
      const localVarPath = `/handbooks/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateHandbook,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * HandBookApi - functional programming interface
 * @export
 */
export const HandBookApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = HandBookApiAxiosParamCreator(configuration);
  return {
    /**
     * 手本情報追加
     * @param {CreateHandbook} createHandbook
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createHandbook(
      createHandbook: CreateHandbook,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Handbook>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createHandbook(
        createHandbook,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * 手本削除
     * @param {number} id 手本を識別するID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteHandbook(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteHandbook(id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * 年月と書体と学年を指定すると対応する手本情報を取得
     * @param {number} [year] 年を指定する
     * @param {number} [month] 月を指定する
     * @param {number} [fontId] 書体ID
     * @param {number} [gradeId] 学年ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getHandbooks(
      year?: number,
      month?: number,
      fontId?: number,
      gradeId?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Handbook>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getHandbooks(
        year,
        month,
        fontId,
        gradeId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * 手本情報が存在する、年と月の情報を取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getHandbooksAvailTerms(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HandbooksAvailTermsInner>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getHandbooksAvailTerms(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * 手本情報更新
     * @param {number} id 手本を識別するID
     * @param {UpdateHandbook} updateHandbook
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateHandbook(
      id: number,
      updateHandbook: UpdateHandbook,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Handbook>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateHandbook(
        id,
        updateHandbook,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * HandBookApi - factory interface
 * @export
 */
export const HandBookApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = HandBookApiFp(configuration);
  return {
    /**
     * 手本情報追加
     * @param {CreateHandbook} createHandbook
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createHandbook(createHandbook: CreateHandbook, options?: any): AxiosPromise<Handbook> {
      return localVarFp
        .createHandbook(createHandbook, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * 手本削除
     * @param {number} id 手本を識別するID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteHandbook(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.deleteHandbook(id, options).then((request) => request(axios, basePath));
    },
    /**
     * 年月と書体と学年を指定すると対応する手本情報を取得
     * @param {number} [year] 年を指定する
     * @param {number} [month] 月を指定する
     * @param {number} [fontId] 書体ID
     * @param {number} [gradeId] 学年ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHandbooks(
      year?: number,
      month?: number,
      fontId?: number,
      gradeId?: number,
      options?: any
    ): AxiosPromise<Array<Handbook>> {
      return localVarFp
        .getHandbooks(year, month, fontId, gradeId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * 手本情報が存在する、年と月の情報を取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHandbooksAvailTerms(options?: any): AxiosPromise<Array<HandbooksAvailTermsInner>> {
      return localVarFp.getHandbooksAvailTerms(options).then((request) => request(axios, basePath));
    },
    /**
     * 手本情報更新
     * @param {number} id 手本を識別するID
     * @param {UpdateHandbook} updateHandbook
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateHandbook(
      id: number,
      updateHandbook: UpdateHandbook,
      options?: any
    ): AxiosPromise<Handbook> {
      return localVarFp
        .updateHandbook(id, updateHandbook, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * HandBookApi - object-oriented interface
 * @export
 * @class HandBookApi
 * @extends {BaseAPI}
 */
export class HandBookApi extends BaseAPI {
  /**
   * 手本情報追加
   * @param {CreateHandbook} createHandbook
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HandBookApi
   */
  public createHandbook(createHandbook: CreateHandbook, options?: AxiosRequestConfig) {
    return HandBookApiFp(this.configuration)
      .createHandbook(createHandbook, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * 手本削除
   * @param {number} id 手本を識別するID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HandBookApi
   */
  public deleteHandbook(id: number, options?: AxiosRequestConfig) {
    return HandBookApiFp(this.configuration)
      .deleteHandbook(id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * 年月と書体と学年を指定すると対応する手本情報を取得
   * @param {number} [year] 年を指定する
   * @param {number} [month] 月を指定する
   * @param {number} [fontId] 書体ID
   * @param {number} [gradeId] 学年ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HandBookApi
   */
  public getHandbooks(
    year?: number,
    month?: number,
    fontId?: number,
    gradeId?: number,
    options?: AxiosRequestConfig
  ) {
    return HandBookApiFp(this.configuration)
      .getHandbooks(year, month, fontId, gradeId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * 手本情報が存在する、年と月の情報を取得
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HandBookApi
   */
  public getHandbooksAvailTerms(options?: AxiosRequestConfig) {
    return HandBookApiFp(this.configuration)
      .getHandbooksAvailTerms(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * 手本情報更新
   * @param {number} id 手本を識別するID
   * @param {UpdateHandbook} updateHandbook
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HandBookApi
   */
  public updateHandbook(id: number, updateHandbook: UpdateHandbook, options?: AxiosRequestConfig) {
    return HandBookApiFp(this.configuration)
      .updateHandbook(id, updateHandbook, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PaymentMethodApi - axios parameter creator
 * @export
 */
export const PaymentMethodApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * ClientSecretを取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientSecret: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/setup-intent`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * PaymentMethodを更新
     * @param {UpdatePaymentMethodParams} updatePaymentMethodParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePaymentMethod: async (
      updatePaymentMethodParams: UpdatePaymentMethodParams,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'updatePaymentMethodParams' is not null or undefined
      assertParamExists(
        'updatePaymentMethod',
        'updatePaymentMethodParams',
        updatePaymentMethodParams
      );
      const localVarPath = `/payment-method/update`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updatePaymentMethodParams,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PaymentMethodApi - functional programming interface
 * @export
 */
export const PaymentMethodApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = PaymentMethodApiAxiosParamCreator(configuration);
  return {
    /**
     * ClientSecretを取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getClientSecret(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientSecret>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getClientSecret(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * PaymentMethodを更新
     * @param {UpdatePaymentMethodParams} updatePaymentMethodParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updatePaymentMethod(
      updatePaymentMethodParams: UpdatePaymentMethodParams,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentMethod>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updatePaymentMethod(
        updatePaymentMethodParams,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * PaymentMethodApi - factory interface
 * @export
 */
export const PaymentMethodApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = PaymentMethodApiFp(configuration);
  return {
    /**
     * ClientSecretを取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getClientSecret(options?: any): AxiosPromise<ClientSecret> {
      return localVarFp.getClientSecret(options).then((request) => request(axios, basePath));
    },
    /**
     * PaymentMethodを更新
     * @param {UpdatePaymentMethodParams} updatePaymentMethodParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePaymentMethod(
      updatePaymentMethodParams: UpdatePaymentMethodParams,
      options?: any
    ): AxiosPromise<PaymentMethod> {
      return localVarFp
        .updatePaymentMethod(updatePaymentMethodParams, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * PaymentMethodApi - object-oriented interface
 * @export
 * @class PaymentMethodApi
 * @extends {BaseAPI}
 */
export class PaymentMethodApi extends BaseAPI {
  /**
   * ClientSecretを取得
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentMethodApi
   */
  public getClientSecret(options?: AxiosRequestConfig) {
    return PaymentMethodApiFp(this.configuration)
      .getClientSecret(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * PaymentMethodを更新
   * @param {UpdatePaymentMethodParams} updatePaymentMethodParams
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PaymentMethodApi
   */
  public updatePaymentMethod(
    updatePaymentMethodParams: UpdatePaymentMethodParams,
    options?: AxiosRequestConfig
  ) {
    return PaymentMethodApiFp(this.configuration)
      .updatePaymentMethod(updatePaymentMethodParams, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ResultApi - axios parameter creator
 * @export
 */
export const ResultApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * 成績優秀者の作品とその成績を取得する。
     * @param {number} year 年を指定する
     * @param {number} month 月を指定する
     * @param {number} fontId 書体ID
     * @param {number} [gradeId] 学年ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHighResults: async (
      year: number,
      month: number,
      fontId: number,
      gradeId?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'year' is not null or undefined
      assertParamExists('getHighResults', 'year', year);
      // verify required parameter 'month' is not null or undefined
      assertParamExists('getHighResults', 'month', month);
      // verify required parameter 'fontId' is not null or undefined
      assertParamExists('getHighResults', 'fontId', fontId);
      const localVarPath = `/results/high-scores`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (year !== undefined) {
        localVarQueryParameter['year'] = year;
      }

      if (month !== undefined) {
        localVarQueryParameter['month'] = month;
      }

      if (fontId !== undefined) {
        localVarQueryParameter['font_id'] = fontId;
      }

      if (gradeId !== undefined) {
        localVarQueryParameter['grade_id'] = gradeId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * 最新で採点された課題の画像を表示
     * @param {number} [year] 年を指定する
     * @param {number} [month] 月を指定する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLatestResults: async (
      year?: number,
      month?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/result/timeline`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (year !== undefined) {
        localVarQueryParameter['year'] = year;
      }

      if (month !== undefined) {
        localVarQueryParameter['month'] = month;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * 総合成績の指定された段級位の一覧を取得する。
     * @param {number} year 年を指定する
     * @param {number} month 月を指定する
     * @param {GetResultsRankIdParameter} rankId 段級位を識別するID ※臨書は空文字
     * @param {number} pageId ページを識別するID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOverallResults: async (
      year: number,
      month: number,
      rankId: GetResultsRankIdParameter,
      pageId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'year' is not null or undefined
      assertParamExists('getOverallResults', 'year', year);
      // verify required parameter 'month' is not null or undefined
      assertParamExists('getOverallResults', 'month', month);
      // verify required parameter 'rankId' is not null or undefined
      assertParamExists('getOverallResults', 'rankId', rankId);
      // verify required parameter 'pageId' is not null or undefined
      assertParamExists('getOverallResults', 'pageId', pageId);
      const localVarPath = `/results/overall`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (year !== undefined) {
        localVarQueryParameter['year'] = year;
      }

      if (month !== undefined) {
        localVarQueryParameter['month'] = month;
      }

      if (rankId !== undefined) {
        localVarQueryParameter['rank_id'] = rankId;
      }

      if (pageId !== undefined) {
        localVarQueryParameter['page_id'] = pageId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * 一年間の段級位の推移をグラフで閲覧する。
     * @param {number} [year] 年を指定する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRankArchives: async (
      year?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/result/rank-changes`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (year !== undefined) {
        localVarQueryParameter['year'] = year;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * 提出した作品とその成績を取得する。
     * @param {number} year 年を指定する
     * @param {number} month 月を指定する
     * @param {string} id ユーザーを識別するID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getResult: async (
      year: number,
      month: number,
      id: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'year' is not null or undefined
      assertParamExists('getResult', 'year', year);
      // verify required parameter 'month' is not null or undefined
      assertParamExists('getResult', 'month', month);
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getResult', 'id', id);
      const localVarPath = `/result/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (year !== undefined) {
        localVarQueryParameter['year'] = year;
      }

      if (month !== undefined) {
        localVarQueryParameter['month'] = month;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * 課題に紐づく作品とその成績を取得する。
     * @param {number} year 年を指定する
     * @param {number} month 月を指定する
     * @param {GetResultsRankIdParameter} rankId 段級位を識別するID ※臨書は空文字
     * @param {number} pageId ページを識別するID
     * @param {number} [fontId] 書体ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getResults: async (
      year: number,
      month: number,
      rankId: GetResultsRankIdParameter,
      pageId: number,
      fontId?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'year' is not null or undefined
      assertParamExists('getResults', 'year', year);
      // verify required parameter 'month' is not null or undefined
      assertParamExists('getResults', 'month', month);
      // verify required parameter 'rankId' is not null or undefined
      assertParamExists('getResults', 'rankId', rankId);
      // verify required parameter 'pageId' is not null or undefined
      assertParamExists('getResults', 'pageId', pageId);
      const localVarPath = `/results`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (year !== undefined) {
        localVarQueryParameter['year'] = year;
      }

      if (month !== undefined) {
        localVarQueryParameter['month'] = month;
      }

      if (rankId !== undefined) {
        localVarQueryParameter['rank_id'] = rankId;
      }

      if (fontId !== undefined) {
        localVarQueryParameter['font_id'] = fontId;
      }

      if (pageId !== undefined) {
        localVarQueryParameter['page_id'] = pageId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * 段級位ごとの人数の分布を表示 ※FontIdがなければ、総合段級位の分布を返す
     * @param {number} year 年を指定する
     * @param {number} month 月を指定する
     * @param {number} [fontId] 書体ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getResultsAmount: async (
      year: number,
      month: number,
      fontId?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'year' is not null or undefined
      assertParamExists('getResultsAmount', 'year', year);
      // verify required parameter 'month' is not null or undefined
      assertParamExists('getResultsAmount', 'month', month);
      const localVarPath = `/results/amount`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (year !== undefined) {
        localVarQueryParameter['year'] = year;
      }

      if (month !== undefined) {
        localVarQueryParameter['month'] = month;
      }

      if (fontId !== undefined) {
        localVarQueryParameter['font_id'] = fontId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ResultApi - functional programming interface
 * @export
 */
export const ResultApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ResultApiAxiosParamCreator(configuration);
  return {
    /**
     * 成績優秀者の作品とその成績を取得する。
     * @param {number} year 年を指定する
     * @param {number} month 月を指定する
     * @param {number} fontId 書体ID
     * @param {number} [gradeId] 学年ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getHighResults(
      year: number,
      month: number,
      fontId: number,
      gradeId?: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HighResultsInner>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getHighResults(
        year,
        month,
        fontId,
        gradeId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * 最新で採点された課題の画像を表示
     * @param {number} [year] 年を指定する
     * @param {number} [month] 月を指定する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getLatestResults(
      year?: number,
      month?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LatestResults>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getLatestResults(
        year,
        month,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * 総合成績の指定された段級位の一覧を取得する。
     * @param {number} year 年を指定する
     * @param {number} month 月を指定する
     * @param {GetResultsRankIdParameter} rankId 段級位を識別するID ※臨書は空文字
     * @param {number} pageId ページを識別するID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOverallResults(
      year: number,
      month: number,
      rankId: GetResultsRankIdParameter,
      pageId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OverallResults>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getOverallResults(
        year,
        month,
        rankId,
        pageId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * 一年間の段級位の推移をグラフで閲覧する。
     * @param {number} [year] 年を指定する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRankArchives(
      year?: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RankArchivesInner>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getRankArchives(year, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * 提出した作品とその成績を取得する。
     * @param {number} year 年を指定する
     * @param {number} month 月を指定する
     * @param {string} id ユーザーを識別するID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getResult(
      year: number,
      month: number,
      id: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Result>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getResult(year, month, id, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * 課題に紐づく作品とその成績を取得する。
     * @param {number} year 年を指定する
     * @param {number} month 月を指定する
     * @param {GetResultsRankIdParameter} rankId 段級位を識別するID ※臨書は空文字
     * @param {number} pageId ページを識別するID
     * @param {number} [fontId] 書体ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getResults(
      year: number,
      month: number,
      rankId: GetResultsRankIdParameter,
      pageId: number,
      fontId?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Results>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getResults(
        year,
        month,
        rankId,
        pageId,
        fontId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * 段級位ごとの人数の分布を表示 ※FontIdがなければ、総合段級位の分布を返す
     * @param {number} year 年を指定する
     * @param {number} month 月を指定する
     * @param {number} [fontId] 書体ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getResultsAmount(
      year: number,
      month: number,
      fontId?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultsAmount>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getResultsAmount(
        year,
        month,
        fontId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ResultApi - factory interface
 * @export
 */
export const ResultApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = ResultApiFp(configuration);
  return {
    /**
     * 成績優秀者の作品とその成績を取得する。
     * @param {number} year 年を指定する
     * @param {number} month 月を指定する
     * @param {number} fontId 書体ID
     * @param {number} [gradeId] 学年ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHighResults(
      year: number,
      month: number,
      fontId: number,
      gradeId?: number,
      options?: any
    ): AxiosPromise<Array<HighResultsInner>> {
      return localVarFp
        .getHighResults(year, month, fontId, gradeId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * 最新で採点された課題の画像を表示
     * @param {number} [year] 年を指定する
     * @param {number} [month] 月を指定する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLatestResults(year?: number, month?: number, options?: any): AxiosPromise<LatestResults> {
      return localVarFp
        .getLatestResults(year, month, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * 総合成績の指定された段級位の一覧を取得する。
     * @param {number} year 年を指定する
     * @param {number} month 月を指定する
     * @param {GetResultsRankIdParameter} rankId 段級位を識別するID ※臨書は空文字
     * @param {number} pageId ページを識別するID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOverallResults(
      year: number,
      month: number,
      rankId: GetResultsRankIdParameter,
      pageId: number,
      options?: any
    ): AxiosPromise<OverallResults> {
      return localVarFp
        .getOverallResults(year, month, rankId, pageId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * 一年間の段級位の推移をグラフで閲覧する。
     * @param {number} [year] 年を指定する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRankArchives(year?: number, options?: any): AxiosPromise<Array<RankArchivesInner>> {
      return localVarFp.getRankArchives(year, options).then((request) => request(axios, basePath));
    },
    /**
     * 提出した作品とその成績を取得する。
     * @param {number} year 年を指定する
     * @param {number} month 月を指定する
     * @param {string} id ユーザーを識別するID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getResult(year: number, month: number, id: string, options?: any): AxiosPromise<Result> {
      return localVarFp
        .getResult(year, month, id, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * 課題に紐づく作品とその成績を取得する。
     * @param {number} year 年を指定する
     * @param {number} month 月を指定する
     * @param {GetResultsRankIdParameter} rankId 段級位を識別するID ※臨書は空文字
     * @param {number} pageId ページを識別するID
     * @param {number} [fontId] 書体ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getResults(
      year: number,
      month: number,
      rankId: GetResultsRankIdParameter,
      pageId: number,
      fontId?: number,
      options?: any
    ): AxiosPromise<Results> {
      return localVarFp
        .getResults(year, month, rankId, pageId, fontId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * 段級位ごとの人数の分布を表示 ※FontIdがなければ、総合段級位の分布を返す
     * @param {number} year 年を指定する
     * @param {number} month 月を指定する
     * @param {number} [fontId] 書体ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getResultsAmount(
      year: number,
      month: number,
      fontId?: number,
      options?: any
    ): AxiosPromise<ResultsAmount> {
      return localVarFp
        .getResultsAmount(year, month, fontId, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * ResultApi - object-oriented interface
 * @export
 * @class ResultApi
 * @extends {BaseAPI}
 */
export class ResultApi extends BaseAPI {
  /**
   * 成績優秀者の作品とその成績を取得する。
   * @param {number} year 年を指定する
   * @param {number} month 月を指定する
   * @param {number} fontId 書体ID
   * @param {number} [gradeId] 学年ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ResultApi
   */
  public getHighResults(
    year: number,
    month: number,
    fontId: number,
    gradeId?: number,
    options?: AxiosRequestConfig
  ) {
    return ResultApiFp(this.configuration)
      .getHighResults(year, month, fontId, gradeId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * 最新で採点された課題の画像を表示
   * @param {number} [year] 年を指定する
   * @param {number} [month] 月を指定する
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ResultApi
   */
  public getLatestResults(year?: number, month?: number, options?: AxiosRequestConfig) {
    return ResultApiFp(this.configuration)
      .getLatestResults(year, month, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * 総合成績の指定された段級位の一覧を取得する。
   * @param {number} year 年を指定する
   * @param {number} month 月を指定する
   * @param {GetResultsRankIdParameter} rankId 段級位を識別するID ※臨書は空文字
   * @param {number} pageId ページを識別するID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ResultApi
   */
  public getOverallResults(
    year: number,
    month: number,
    rankId: GetResultsRankIdParameter,
    pageId: number,
    options?: AxiosRequestConfig
  ) {
    return ResultApiFp(this.configuration)
      .getOverallResults(year, month, rankId, pageId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * 一年間の段級位の推移をグラフで閲覧する。
   * @param {number} [year] 年を指定する
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ResultApi
   */
  public getRankArchives(year?: number, options?: AxiosRequestConfig) {
    return ResultApiFp(this.configuration)
      .getRankArchives(year, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * 提出した作品とその成績を取得する。
   * @param {number} year 年を指定する
   * @param {number} month 月を指定する
   * @param {string} id ユーザーを識別するID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ResultApi
   */
  public getResult(year: number, month: number, id: string, options?: AxiosRequestConfig) {
    return ResultApiFp(this.configuration)
      .getResult(year, month, id, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * 課題に紐づく作品とその成績を取得する。
   * @param {number} year 年を指定する
   * @param {number} month 月を指定する
   * @param {GetResultsRankIdParameter} rankId 段級位を識別するID ※臨書は空文字
   * @param {number} pageId ページを識別するID
   * @param {number} [fontId] 書体ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ResultApi
   */
  public getResults(
    year: number,
    month: number,
    rankId: GetResultsRankIdParameter,
    pageId: number,
    fontId?: number,
    options?: AxiosRequestConfig
  ) {
    return ResultApiFp(this.configuration)
      .getResults(year, month, rankId, pageId, fontId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * 段級位ごとの人数の分布を表示 ※FontIdがなければ、総合段級位の分布を返す
   * @param {number} year 年を指定する
   * @param {number} month 月を指定する
   * @param {number} [fontId] 書体ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ResultApi
   */
  public getResultsAmount(
    year: number,
    month: number,
    fontId?: number,
    options?: AxiosRequestConfig
  ) {
    return ResultApiFp(this.configuration)
      .getResultsAmount(year, month, fontId, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * SubscriptionApi - axios parameter creator
 * @export
 */
export const SubscriptionApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * サブスクリプションの中止
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelSubscription: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/subscription/cancel`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SubscriptionApi - functional programming interface
 * @export
 */
export const SubscriptionApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SubscriptionApiAxiosParamCreator(configuration);
  return {
    /**
     * サブスクリプションの中止
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cancelSubscription(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.cancelSubscription(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * SubscriptionApi - factory interface
 * @export
 */
export const SubscriptionApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = SubscriptionApiFp(configuration);
  return {
    /**
     * サブスクリプションの中止
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelSubscription(options?: any): AxiosPromise<void> {
      return localVarFp.cancelSubscription(options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * SubscriptionApi - object-oriented interface
 * @export
 * @class SubscriptionApi
 * @extends {BaseAPI}
 */
export class SubscriptionApi extends BaseAPI {
  /**
   * サブスクリプションの中止
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SubscriptionApi
   */
  public cancelSubscription(options?: AxiosRequestConfig) {
    return SubscriptionApiFp(this.configuration)
      .cancelSubscription(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * UserInfoApi - axios parameter creator
 * @export
 */
export const UserInfoApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * 自分のユーザー情報取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLoginUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/userinfo`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UserInfoApi - functional programming interface
 * @export
 */
export const UserInfoApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UserInfoApiAxiosParamCreator(configuration);
  return {
    /**
     * 自分のユーザー情報取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getLoginUser(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Userinfo>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getLoginUser(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * UserInfoApi - factory interface
 * @export
 */
export const UserInfoApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = UserInfoApiFp(configuration);
  return {
    /**
     * 自分のユーザー情報取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLoginUser(options?: any): AxiosPromise<Userinfo> {
      return localVarFp.getLoginUser(options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * UserInfoApi - object-oriented interface
 * @export
 * @class UserInfoApi
 * @extends {BaseAPI}
 */
export class UserInfoApi extends BaseAPI {
  /**
   * 自分のユーザー情報取得
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserInfoApi
   */
  public getLoginUser(options?: AxiosRequestConfig) {
    return UserInfoApiFp(this.configuration)
      .getLoginUser(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * ユーザー情報登録
     * @param {CreateUser} createUser
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUser: async (
      createUser: CreateUser,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'createUser' is not null or undefined
      assertParamExists('createUser', 'createUser', createUser);
      const localVarPath = `/users`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createUser,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * 全ユーザー情報取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUsers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/users`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary 各段級位情報つきのユーザー情報リストを取得
     * @param {number} [courseType] コースの種別(一般,学生)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUsersWithRanks: async (
      courseType?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/users/ranks`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (courseType !== undefined) {
        localVarQueryParameter['course_type'] = courseType;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * トライアル開始
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    startTrial: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/users/trial`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * ユーザー情報1件更新
     * @param {string} id ユーザーを識別するID
     * @param {UpdateUser} updateUser
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUser: async (
      id: string,
      updateUser: UpdateUser,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('updateUser', 'id', id);
      // verify required parameter 'updateUser' is not null or undefined
      assertParamExists('updateUser', 'updateUser', updateUser);
      const localVarPath = `/users/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateUser,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration);
  return {
    /**
     * ユーザー情報登録
     * @param {CreateUser} createUser
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createUser(
      createUser: CreateUser,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(createUser, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * 全ユーザー情報取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUsers(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUsers(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary 各段級位情報つきのユーザー情報リストを取得
     * @param {number} [courseType] コースの種別(一般,学生)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUsersWithRanks(
      courseType?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserWithRanks>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUsersWithRanks(
        courseType,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * トライアル開始
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async startTrial(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.startTrial(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * ユーザー情報1件更新
     * @param {string} id ユーザーを識別するID
     * @param {UpdateUser} updateUser
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateUser(
      id: string,
      updateUser: UpdateUser,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(id, updateUser, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = UsersApiFp(configuration);
  return {
    /**
     * ユーザー情報登録
     * @param {CreateUser} createUser
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUser(createUser: CreateUser, options?: any): AxiosPromise<User> {
      return localVarFp.createUser(createUser, options).then((request) => request(axios, basePath));
    },
    /**
     * 全ユーザー情報取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUsers(options?: any): AxiosPromise<Array<User>> {
      return localVarFp.getUsers(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary 各段級位情報つきのユーザー情報リストを取得
     * @param {number} [courseType] コースの種別(一般,学生)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUsersWithRanks(courseType?: number, options?: any): AxiosPromise<Array<UserWithRanks>> {
      return localVarFp
        .getUsersWithRanks(courseType, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * トライアル開始
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    startTrial(options?: any): AxiosPromise<void> {
      return localVarFp.startTrial(options).then((request) => request(axios, basePath));
    },
    /**
     * ユーザー情報1件更新
     * @param {string} id ユーザーを識別するID
     * @param {UpdateUser} updateUser
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUser(id: string, updateUser: UpdateUser, options?: any): AxiosPromise<User> {
      return localVarFp
        .updateUser(id, updateUser, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
  /**
   * ユーザー情報登録
   * @param {CreateUser} createUser
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public createUser(createUser: CreateUser, options?: AxiosRequestConfig) {
    return UsersApiFp(this.configuration)
      .createUser(createUser, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * 全ユーザー情報取得
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public getUsers(options?: AxiosRequestConfig) {
    return UsersApiFp(this.configuration)
      .getUsers(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary 各段級位情報つきのユーザー情報リストを取得
   * @param {number} [courseType] コースの種別(一般,学生)
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public getUsersWithRanks(courseType?: number, options?: AxiosRequestConfig) {
    return UsersApiFp(this.configuration)
      .getUsersWithRanks(courseType, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * トライアル開始
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public startTrial(options?: AxiosRequestConfig) {
    return UsersApiFp(this.configuration)
      .startTrial(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * ユーザー情報1件更新
   * @param {string} id ユーザーを識別するID
   * @param {UpdateUser} updateUser
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsersApi
   */
  public updateUser(id: string, updateUser: UpdateUser, options?: AxiosRequestConfig) {
    return UsersApiFp(this.configuration)
      .updateUser(id, updateUser, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ViewpointApi - axios parameter creator
 * @export
 */
export const ViewpointApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * 書体IDで検索して該当する評価項目を取得する
     * @param {number} [fontId] 書体ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getViewpoints: async (
      fontId?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/viewpoints`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication Bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (fontId !== undefined) {
        localVarQueryParameter['font_id'] = fontId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ViewpointApi - functional programming interface
 * @export
 */
export const ViewpointApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ViewpointApiAxiosParamCreator(configuration);
  return {
    /**
     * 書体IDで検索して該当する評価項目を取得する
     * @param {number} [fontId] 書体ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getViewpoints(
      fontId?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Viewpoint>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getViewpoints(fontId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ViewpointApi - factory interface
 * @export
 */
export const ViewpointApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = ViewpointApiFp(configuration);
  return {
    /**
     * 書体IDで検索して該当する評価項目を取得する
     * @param {number} [fontId] 書体ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getViewpoints(fontId?: number, options?: any): AxiosPromise<Array<Viewpoint>> {
      return localVarFp.getViewpoints(fontId, options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * ViewpointApi - object-oriented interface
 * @export
 * @class ViewpointApi
 * @extends {BaseAPI}
 */
export class ViewpointApi extends BaseAPI {
  /**
   * 書体IDで検索して該当する評価項目を取得する
   * @param {number} [fontId] 書体ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ViewpointApi
   */
  public getViewpoints(fontId?: number, options?: AxiosRequestConfig) {
    return ViewpointApiFp(this.configuration)
      .getViewpoints(fontId, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
