import { Box, Paper, Tab, Table, TableContainer, Tabs } from '@mui/material';
import { styled } from '@mui/material/styles';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { useEffect, useMemo } from 'react';

import TableCase from '@/components/parts/TableCase';
import { userRanks, userSelector } from '@/services/userSlice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#767676',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const generalTableHeaderLabels = [
  'ユーザー名',
  '総合',
  '楷書',
  '行書',
  '草書',
  '隷書',
  '臨書',
  '条幅',
  'ペン字',
  '実用書',
  '仮名',
];

const studentTableHeaderLabels = ['ユーザー名', '学年', '硬筆', '条幅', '半紙'];

const TableData = ({
  label,
  size = 'normal',
  width = 50,
}: {
  label: string;
  size?: string;
  width?: number;
}) => (
  <Box
    sx={{
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      fontSize: size === 'bold' ? '20px' : '14px',
      fontWeight: size === 'bold' ? 'bold' : 'normal',
      width,
    }}
  >
    {label}
  </Box>
);

export default function userRanksManagement() {
  const dispatch = useAppDispatch();
  const { userWithRanks } = useAppSelector(userSelector);

  const [tabValue, setTabValue] = React.useState(0);

  useEffect(() => {
    dispatch(userRanks(1));
  }, [dispatch]);

  const generalUsers = userWithRanks.filter((user) => user.course_type === 1);

  const studentUsers = userWithRanks.filter((user) => user.course_type === 2);

  const handleChange = (event: React.SyntheticEvent, newTabValue: number) => {
    setTabValue(newTabValue);
    dispatch(userRanks(newTabValue + 1));
  };

  const generalRows = useMemo(
    () =>
      generalUsers.map((row) => {
        const cells = row.ranks
          ?.map((_, i) => {
            if (i > 9) {
              return null;
            }
            const fontId = i === 0 ? null : i;
            const rank = row.ranks?.find((r) => r.font_id === fontId);
            return (
              <StyledTableCell key={i}>
                <TableData
                  label={(fontId !== 5 && rank?.rank_name) || ''}
                  size={fontId === null ? 'bold' : 'normal'}
                  width={50}
                />
              </StyledTableCell>
            );
          })
          .filter((cell) => cell !== null);

        return (
          <StyledTableRow key={row.user_id}>
            <StyledTableCell component="th" scope="row">
              <TableData label={row.nickname || ''} width={200} />
            </StyledTableCell>
            {cells}
          </StyledTableRow>
        );
      }),
    [generalUsers]
  );

  const studentRows = useMemo(
    () =>
      studentUsers.map((row) => {
        const cells = row.ranks
          ?.map((_, i) => {
            if (i > 2) {
              return null;
            }
            return (
              <StyledTableCell key={i}>
                <TableData label={row.ranks?.[i]?.rank_name || ''} width={50} />
              </StyledTableCell>
            );
          })
          .filter((cell) => cell !== null);

        return (
          <StyledTableRow key={row.user_id}>
            <StyledTableCell component="th" scope="row">
              <TableData label={row.nickname || ''} width={200} />
            </StyledTableCell>
            <StyledTableCell component="th" scope="row">
              <TableData label={row.student_grade || ''} width={100} />
            </StyledTableCell>
            {cells}
          </StyledTableRow>
        );
      }),
    [studentUsers]
  );

  console.log('generalRows', generalRows);
  console.log('generalTableHeaderLabels', generalTableHeaderLabels);
  return (
    <Box sx={{ p: 2 }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Tabs value={tabValue} onChange={handleChange}>
            <Tab label="一般" />
            <Tab label="学生" />
          </Tabs>
        </Box>
      </Box>
      <TableCase index={0} value={tabValue}>
        <TableContainer component={Paper} style={{ maxHeight: '82vh' }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {generalTableHeaderLabels.map((label) => (
                  <StyledTableCell key={label}>{label}</StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>{generalRows}</TableBody>
          </Table>
        </TableContainer>
      </TableCase>
      <TableCase index={1} value={tabValue}>
        <TableContainer component={Paper} style={{ maxHeight: '82vh' }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {studentTableHeaderLabels.map((label) => (
                  <StyledTableCell key={label}>{label}</StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>{studentRows}</TableBody>
          </Table>
        </TableContainer>
      </TableCase>
    </Box>
  );
}
