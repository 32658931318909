import CancelIcon from '@mui/icons-material/Cancel';
import {
  AppBar,
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  IconButton,
  Link,
  // Modal,
  Radio,
  RadioGroup,
  // Stack,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import React, { forwardRef, ReactNode, useEffect } from 'react';
import { useForm } from 'react-hook-form';

// import CreditCardForm from '@/components/CreditCardForm';
import DataItemRow from '@/components/parts/DataItemRow';
import { useToast } from '@/components/ToastProvider';
import { UpdateUser, UserinfoSubscriptionStatusEnum } from '@/generated/types/typescript-axios';
import { authSelector, fetchUserInfo } from '@/services/authSlice';
// import {
//   cancelSubscription,
//   clearStatus as subscriptionClearStatus,
//   subscriptionSelector,
// } from '@/services/subscriptionSlice';
import { clearStatus as userClearStatus, updateUser, userSelector } from '@/services/userSlice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { AsyncStatus, URL } from '@/types';

const MyPage = () => {
  const { account } = useAppSelector(authSelector);
  const dispatch = useAppDispatch();
  const { status: userStatus } = useAppSelector(userSelector);
  // const { status: subscriptionStatus } = useAppSelector(subscriptionSelector);
  const { toast } = useToast();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { isValid, errors },
  } = useForm<UpdateUser>({
    mode: 'onChange',
    defaultValues: {
      email: account?.email,
      nickname: account?.nickname,
      last_name: account?.last_name,
      first_name: account?.first_name,
      gender_id: account?.gender_id,
      prefecture: account?.prefecture,
      birthday: account?.birthday,
      is_experienced: account?.is_experienced,
      course_type: account?.course_type,
      parent_last_name: account?.parent_last_name,
      parent_first_name: account?.parent_first_name,
      credit_card: account?.credit_card,
      is_private: account?.is_private,
      trial_end_date: account?.trial_end_date,
      subscription_status: account?.subscription_status,
    },
  });

  const onSubmitForm = (updateUserData: UpdateUser) => {
    dispatch(updateUser({ id: account?.id as string, ...updateUserData }));
  };

  const resetUserStatus = () => {
    dispatch(userClearStatus());
    dispatch(fetchUserInfo());
  };

  // const resetSubscriptionStatus = () => {
  //   dispatch(subscriptionClearStatus());
  //   dispatch(fetchUserInfo());
  // };

  useEffect(() => {
    if (userStatus.update === AsyncStatus.SUCCESS) {
      toast({ message: 'ユーザー情報を更新しました' });
      resetUserStatus();
    }
    if (userStatus.update === AsyncStatus.FAILED) {
      toast({ message: 'ユーザー情報更新に失敗しました', type: 'error' });
      resetUserStatus();
    }
  }, [userStatus.update]);

  // useEffect(() => {
  //   if (subscriptionStatus.cancel === AsyncStatus.SUCCESS) {
  //     toast({ message: 'コースを中止しました' });
  //     handleSubscriptionCancelClose();
  //     resetSubscriptionStatus();
  //   }
  //   if (subscriptionStatus.cancel === AsyncStatus.FAILED) {
  //     toast({ message: 'コース中止に失敗しました', type: 'error' });
  //     resetSubscriptionStatus();
  //   }
  // }, [subscriptionStatus.cancel]);

  // FIXME 性別のID->ラベルの変換、全体で共通化したい
  const genderIdToLabel = (genderId: number) => {
    switch (genderId) {
      case 1:
        return '女性';
      case 2:
        return '男性';
      case 3:
        return '未回答';
      default:
        return '';
    }
  };

  // FIXME コースのタイプ->ラベルの変換、全体で共通化したい
  const courseTypeToLabel = (courseType: number) => {
    switch (courseType) {
      case 1:
        return '一般コース';
      case 2:
        return '学生コース';
      default:
        return '';
    }
  };

  const handleIsExperiencedChange = (event: any) => {
    setValue('is_experienced', event.target.value === 'true');
  };

  const handleIsPrivateChange = (event: any) => {
    setValue('is_private', event.target.value === 'true');
  };

  // const [open, setOpen] = useState(false);

  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);

  // const [subscriptionCancelOpen, setSubscriptionCancelOpen] = useState(false);

  // const handleSubscriptionCancelOpen = () => setSubscriptionCancelOpen(true);
  // const handleSubscriptionCancelClose = () => setSubscriptionCancelOpen(false);

  // const handleSubscriptionCancel = () => {
  //   dispatch(cancelSubscription());
  // };

  // const addCreditCard = () => {
  //   const queryParameter = `?prefilled_email=${account.email}&client_reference_id=${account.id}`;
  //   const url =
  //     account.course_type === 1
  //       ? process.env.REACT_APP_STRIPE_PAYMENT_LINK_URL
  //       : process.env.REACT_APP_STRIPE_PAYMENT_LINK_URL_STUDENT;
  //   window.open(url + queryParameter);
  // };

  const ModalBody = forwardRef(
    (
      {
        handleClose,
        title,
        children,
      }: {
        handleClose: any;
        title: string;
        children: ReactNode;
      },
      ref: React.Ref<any>
    ) => (
      <Box
        ref={ref}
        tabIndex={0}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: 600,
          bgcolor: 'background.paper',
          boxShadow: 24,
          width: '80%',
          borderRadius: 2,
        }}
      >
        <AppBar
          position="static"
          color="default"
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
          }}
        >
          <Toolbar variant="dense">
            <Typography sx={{ fontSize: 16, fontWeight: 'bold' }}>{title}</Typography>
            <IconButton
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                backgroundColor: '#f5f5f5',
              }}
            >
              <CancelIcon sx={{ backgroundColor: '#333', color: '#f5f5f5', borderRadius: '50%' }} />
            </IconButton>
          </Toolbar>
        </AppBar>
        {children}
      </Box>
    )
  );

  ModalBody.displayName = 'ModalBody';

  return (
    <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography variant="h5" align="center" sx={{ mt: 2 }}>
        マイページ
      </Typography>
      <Container
        maxWidth="xs"
        component="form"
        onSubmit={handleSubmit(onSubmitForm)}
        sx={{ display: 'flex', flexDirection: 'column', gap: 4, mt: 2 }}
      >
        <DataItemRow title="ユーザー名">
          <TextField
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            defaultValue={account?.nickname}
            {...register('nickname', {
              required: 'ユーザー名を入力してください',
              maxLength: { value: 20, message: '20文字以内で入力してください' },
            })}
            error={!!errors.nickname}
            helperText={errors.nickname && errors.nickname?.message}
          />
        </DataItemRow>
        <DataItemRow title="メールアドレス">
          <Box>{account?.email}</Box>
        </DataItemRow>
        <DataItemRow title="氏名">
          <Box>
            {account?.last_name} {account?.first_name}
          </Box>
        </DataItemRow>
        <DataItemRow title="生年月日">
          <Box>{dayjs(account?.birthday).format('YYYY年M月D日')}</Box>
        </DataItemRow>
        <DataItemRow title="性別">
          <Box>{genderIdToLabel(account?.gender_id ?? 1)}</Box>
        </DataItemRow>
        <DataItemRow title="都道府県">
          <Box>{account?.prefecture}</Box>
        </DataItemRow>
        <DataItemRow title="書道経験">
          <FormControl component="label">
            <RadioGroup
              row
              defaultValue={account?.is_experienced}
              onChange={handleIsExperiencedChange}
            >
              <FormControlLabel value={true} control={<Radio size="small" />} label="あり" />
              <FormControlLabel value={false} control={<Radio size="small" />} label="なし" />
            </RadioGroup>
          </FormControl>
        </DataItemRow>
        <DataItemRow
          title="受講コース"
          // sub={
          //   account.subscription_status === UserinfoSubscriptionStatusEnum.Active && (
          //     <Link onClick={handleSubscriptionCancelOpen}>コースを中止する</Link>
          //   )
          // }
        >
          <Box>
            {courseTypeToLabel(account?.course_type ?? 1)}
            {(account.subscription_status === UserinfoSubscriptionStatusEnum.Cancel &&
              '（休止中）') ||
              (account.subscription_status === UserinfoSubscriptionStatusEnum.WaitingCancel &&
                `（${
                  dayjs().date() > 24 ? dayjs().month() + 2 : dayjs().month() + 1
                }/24まで利用可能）`)}
          </Box>
        </DataItemRow>
        {/* <Modal open={subscriptionCancelOpen} onClose={handleSubscriptionCancelClose}>
          <ModalBody title="コース中止" handleClose={handleSubscriptionCancelClose}>
            <Container sx={{ px: 3, minHeight: '55vh' }}>
              <Box sx={{ py: 6 }}>
                <Typography sx={{ mb: 2 }}>現在受講中のコースを中止しますか？</Typography>
                <Typography>・当月のコース料金の支払いは発生いたします。</Typography>
                <Typography>・コースを再開する月まで料金の支払いは発生いたしません。</Typography>
              </Box>
              <Button
                onClick={handleSubscriptionCancel}
                variant="contained"
                sx={{
                  bgcolor: '#D32F2F',
                  width: '100%',
                  '&:hover': {
                    bgcolor: '#b02727',
                  },
                }}
                disabled={subscriptionStatus.cancel === AsyncStatus.LOADING}
              >
                はい、中止します
              </Button>
            </Container>
          </ModalBody>
        </Modal> */}
        {/* <Box sx={{ display: 'flex', alignItems: 'left', gap: 2, flexDirection: 'column' }}>
          <Box sx={{ color: '#666' }}>お支払い情報</Box>
          {account.stripe_customer_id ? (
            <Box
              sx={{
                border: 1,
                borderColor: 'rgba(84, 109, 117, 0.16)',
                borderRadius: 2,
                px: 1,
                py: 2,
              }}
            >
              <Stack direction="row" sx={{ alignItems: 'center' }}>
                <Stack direction="row" sx={{ width: '100%', gap: 1 }}>
                  <img
                    src={`/${account?.credit_card?.brand}.png`}
                    alt=""
                    className="tw-object-contain tw-w-[40px] tw-my-2 tw-max-h-[25px]"
                  ></img>
                  <Box>
                    <Box sx={{ fontSize: 14, color: '#000000' }}>
                      **********{account?.credit_card?.num}のカード
                    </Box>
                    {account?.credit_card?.expired_month &&
                    account?.credit_card?.expired_month < 10 ? (
                      <Box sx={{ fontSize: 14, color: 'rgba(0, 0, 0, 0.6)' }}>
                        有効期限：0{account?.credit_card?.expired_month}/
                        {account?.credit_card?.expired_year}
                      </Box>
                    ) : (
                      <Box sx={{ fontSize: 14, color: 'rgba(0, 0, 0, 0.6)' }}>
                        有効期限：{account?.credit_card?.expired_month}/
                        {account?.credit_card?.expired_year}
                      </Box>
                    )}
                  </Box>
                </Stack>
                <Button
                  variant="contained"
                  onClick={handleOpen}
                  sx={{
                    color: '#1976D2',
                    px: 2,
                    py: 0,
                    borderRadius: 1,
                    border: 1,
                    bgcolor: '#FFFFFF',
                    borderColor: '#1976D2',
                    height: '25px',
                    boxShadow: 0,
                    '&:hover': {
                      color: '#1976D2',
                      bgcolor: '#FFFFFF',
                      border: '1px solid #1976D2',
                    },
                  }}
                >
                  編集
                </Button>
                <Modal open={open} onClose={handleClose}>
                  <ModalBody title="カード編集" handleClose={handleClose}>
                    <CreditCardForm />
                  </ModalBody>
                </Modal>
              </Stack>
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Box>
                お支払い情報を追加していただくことで受講コースが一般コースに変更されます。
                <br /> 詳しくは、
                <Link target="_blank">こちら</Link>
              </Box>
              <Button
                variant="contained"
                onClick={addCreditCard}
                sx={{
                  justifyContent: 'center',
                  mt: 2,
                  height: 36,
                  borderRadius: 1,
                  bgcolor: '#FFFFFF',
                  color: '#1976D2',
                  border: '1px solid #1976D2',
                  boxShadow: 0,
                  width: '100%',
                  '&:hover': {
                    color: '#1976D2',
                    bgcolor: '#FFFFFF',
                    border: '1px solid #1976D2',
                  },
                }}
              >
                コースを切り替える
              </Button>
            </Box>
          )}
        </Box> */}
        {account?.parent_last_name !== null && account?.parent_first_name !== null && (
          <DataItemRow title="保護者氏名">
            <Box>
              {account?.parent_last_name} {account?.parent_first_name}
            </Box>
          </DataItemRow>
        )}
        <Box>
          <DataItemRow title="非公開アカウント">
            <FormControl component="label">
              <RadioGroup row defaultValue={account?.is_private} onChange={handleIsPrivateChange}>
                <FormControlLabel value={true} control={<Radio size="small" />} label="はい" />
                <FormControlLabel value={false} control={<Radio size="small" />} label="いいえ" />
              </RadioGroup>
            </FormControl>
          </DataItemRow>
          <Box sx={{ color: 'grey.600', fontSize: 14 }}>
            ※全体成績から非公開になります。
            <Link href={URL.HELP_PRIVATE_ACCOUNT} target="_blank">
              詳しくはこちら
            </Link>
          </Box>
        </Box>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <Button
            type="submit"
            disabled={userStatus.update === AsyncStatus.LOADING || !isValid}
            variant="contained"
            sx={{ width: 'fit-content' }}
          >
            更新
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default MyPage;
